import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { TERMAdd, TERMDelete, TERMGet, TERMUpdate, SetTERMObj } from "../../../redux/actions/Terms/Terms.actions";
import ActionIcon from "../../Utility/ActionIcon";
import CustomButton from "../../Utility/Button";
import { DashboardBox, DashboardTable } from "../../Utility/DashboardBox";
function Terms() {
  const [heading, setHeading] = useState("");
  const [description, setDescription] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const termsArr = useSelector((state) => state.Terms.terms);
  const termObj = useSelector((state) => state.Terms.termObj);
  const [isUpdate, setIsUpdate] = useState(false);

  const handleEditFaq = (row) => {
    console.log(row);
    dispatch(SetTERMObj(row));
  };

  const handleDeleteFaq = (id) => {
    dispatch(TERMDelete(id));
    handleGet();
  };

  const tag_columns = [
    {
      name: "ID",
      cell: (row, index) => index + 1,
      sortable: false,
    },
    {
      name: "Question",
      selector: (row) => row.heading,
      sortable: true,
    },
    {
      name: "Answer",
      cell: (row) => `${row?.description.slice(0, 50)}...`,
    },
    {
      name: "Action",
      minWidth: "210px",
      maxWidth: "211px",
      cell: (row) => <ActionIcon Uniquekey={row.id} remove edit isRedirected={true} deletePath="/SetUp/Terms" onDeleteClick={() => handleDeleteFaq(row?._id)} editPath="/SetUp/Terms" onEditClick={() => handleEditFaq(row)} />,
    },
  ];

  const handleFaqAdd = async () => {
    if (isUpdate) {
      let obj = { heading, description };
      dispatch(TERMUpdate(obj, termObj?._id));
      dispatch(SetTERMObj(null));
      // navigate(-1)
    } else {
      let obj = { heading, description };
      dispatch(TERMAdd(obj));
      // navigate(-1)
    }
    setIsUpdate(false);
    setHeading("");
    setDescription("");
    handleGet();
  };

  const handleGet = () => {
    dispatch(TERMGet());
  };

  useEffect(() => {
    handleGet();
  }, []);

  useEffect(() => {
    if (termObj) {
      setHeading(termObj?.heading);
      setDescription(termObj?.description);
      setIsUpdate(true);
    }
  }, [termObj]);

  return (
    <main>
      <section className="product-category">
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-12 col-md-4">
              <h5 className="blue-1 mb-4">{isUpdate ? "Update" : "Add"} Terms</h5>
              <DashboardBox>
                <form action="#" className="form row">
                  <div className="col-12">
                    <label className="blue-1 fs-12">
                      Heading <span className="red">*</span>
                    </label>
                    <input type="text" value={heading} onChange={(e) => setHeading(e.target.value)} className="form-control" />
                  </div>
                  <div className="col-12">
                    <label className="blue-1 fs-12">
                      Description <span className="red">*</span>
                    </label>
                    <textarea value={description} onChange={(e) => setDescription(e.target.value)} type="text" className="form-control" />
                  </div>
                  <div className="col-12 mt-2">
                    <CustomButton btntype="button" ClickEvent={() => handleFaqAdd()} isBtn iconName="fa-solid fa-check" btnName="Save" />
                  </div>
                </form>
              </DashboardBox>
            </div>
            <div className="col-12 col-md-8">
              <div className="d-flex gap-3 justify-content-between mb-3 align-items-center">
                <h5 className="blue-1 m-0">Terms List</h5>
                {/* <SearchBox extraClass="bg-white" /> */}
              </div>
              <DashboardTable>
                <DataTable columns={tag_columns} data={termsArr && termsArr.length ? termsArr : []} pagination />
              </DashboardTable>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default Terms;
