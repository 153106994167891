import { toastError, toastSuccess } from "../../../components/Utility/ToastUtils";
import * as Faq from "../../actions/Faq/Faq.actions";

const initialState = {
  faqs: null,
  faqObj: null,
  loading: false,
  error: null,
};

export const FaqReducer = (state = initialState, action) => {
  switch (action.type) {
    case Faq.FAQ_ADD:
      return {
        ...state,
        loading: true,
      };
    case Faq.FAQ_ADD_SUCCESS:
      //   console.log(act);
      toastSuccess(action.payload);

      return {
        ...state,
        loading: false,
      };
    case Faq.FAQ_ADD_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case Faq.GET_ALL_FAQS:
      return {
        ...state,
        loading: true,
      };
    case Faq.GET_ALL_FAQS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        faqs: action.payload.data,
      };
    case Faq.GET_ALL_FAQS_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case Faq.DELETE_FAQ_BY_ID:
      return {
        ...state,
        loading: true,
      };
    case Faq.DELETE_FAQ_BY_ID_SUCCESS:
      toastSuccess(action.payload.message);
      return {
        ...state,
        loading: false,
        error: null,
      };
    case Faq.DELETE_FAQ_BY_ID_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case Faq.SET_FAQ_OBJ:
      return {
        ...state,
        loading: true,
      };
    case Faq.SET_FAQ_OBJ_SUCCESS:
      toastSuccess(action.payload.message);
      return {
        ...state,
        faqObj: action.payload.data,
        loading: false,
        error: null,
      };
    case Faq.SET_FAQ_OBJ_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case Faq.UPDATE_FAQ_BY_ID:
      return {
        ...state,
        loading: true,
      };
    case Faq.UPDATE_FAQ_BY_ID_SUCCESS:
      toastSuccess(action.payload.message);
      return {
        ...state,
        loading: false,
        error: null,
      };
    case Faq.UPDATE_FAQ_BY_ID_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
