import { toastError } from "../../../components/Utility/ToastUtils";
import * as Support from "../../actions/Support/Support.actions";

const initialState = {
  supportTickets: null,
  messages: null,
  supportObj: null,
  loading: false,
  error: null,
};

export const SupportReducer = (state = initialState, action) => {
  switch (action.type) {
    case Support.GET_ALL_SUPPORT_TICKETS:
      return {
        ...state,
        loading: true,
      };
    case Support.GET_ALL_SUPPORT_TICKETS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        supportTickets: action.payload.data,
      };
    case Support.GET_ALL_SUPPORT_TICKETS_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case Support.GET_MESSAGES_BY_TICKET_ID:
      return {
        ...state,
        loading: true,
      };
    case Support.GET_MESSAGES_BY_TICKET_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        messages: action.payload.data,
        supportObj: action.payload.supportObj,
      };
    case Support.GET_MESSAGES_BY_TICKET_ID_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case Support.CLOSE_TICKET_BY_TICKET_ID:
      return {
        ...state,
        loading: true,
      };
    case Support.CLOSE_TICKET_BY_TICKET_ID_SUCCESS:
      toastError(action.payload.message);
      return {
        ...state,
        loading: false,
        error: null,
      };
    case Support.CLOSE_TICKET_BY_TICKET_ID_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case Support.SEND_MESSAGE_IN_CHAT_BY_ID:
      return {
        ...state,
        loading: true,
      };
    case Support.SEND_MESSAGE_IN_CHAT_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case Support.SEND_MESSAGE_IN_CHAT_BY_ID_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
