import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { getUserById } from "../../redux/actions/Users/users.actions";
import { updateUserAddressApprove } from "../../services/users.service";
import { images } from "../Images/Images";
import CustomButton from "../Utility/Button";
import { DashboardBox, DashboardTable } from "../Utility/DashboardBox";
import tabClick from "../Utility/TabClick";
import { toastError, toastSuccess } from "../Utility/ToastUtils";
import { generateFilePath } from "../Utility/utils";
function CustomerDetail() {
  // ==============================================================================================

  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();

  const customerData = useSelector((state) => state.users.userObj);
  const getCustomer = () => {
    dispatch(getUserById(searchParams.get("id")));
  };
  useEffect(() => {
    getCustomer();
  }, []);

  const [tabList, settabList] = useState([
    {
      tabName: "ORDERS",
      active: true,
    },

    {
      tabName: "ADDRESSES",
      active: false,
    },
  ]);
  const table_columns = [
    {
      name: "SL",
      selector: (row, index) => index + 1,
      sortable: true,
    },
    {
      name: "Date",
      selector: (row) => new Date(row?.createdAt).toDateString(),
    },
    {
      name: "Order ID",
      selector: (row) => row?._id,
    },
    {
      name: "Phone",
      selector: (row) => row?.addressObj?.phone,
    },
    {
      name: "Total Product QTY",
      selector: (row) => row?.items?.reduce((acc, el) => acc + el.quantity, 0),
    },
    {
      name: "Total Amount",
      selector: (row) => row.totalAmount,
    },
    {
      name: "Order Status",
      button: true,
      width: "10%",
      cell: (row) => <CustomButton redBtn={row?.status == "CANCELLED"} greenBtn={row?.status != "CANCELLED"} btnName={row?.status} />,
    },
    {
      name: "Is Paid",
      button: true,
      width: "10%",
      cell: (row) => <CustomButton redBtn={row?.paymentObj?.paymentChk != 1} greenBtn={row?.paymentObj?.paymentChk == 1} btnName={row?.paymentObj?.paymentChk == 1 ? "PAID" : "PENDING"} />,
    },
    {
      name: "Action",
      cell: (row) => <CustomButton btntype="button" path={`/Order/Sale-Detail?orderId=${row?._id}`} greenBtn btnName={"View"} />,
    },
  ];

  const handleAddressApprove = async (row) => {
    try {
      let obj = {
        ...row.approvalObj
      }
      const { data: res } = await updateUserAddressApprove(obj, row?._id);
      if (res) {
        // toastSuccess({message:res?.message})
      }
    } catch (error) {
      toastError(error);
    }
  }

  const address_columns = [
    {
      name: "Full Name",
      sortable: true,
      selector: (row) => row.firstName + row?.lastName,
    },
    {
      name: "Address",
      selector: (row) => row.address,
    },
    {
      name: "State",
      minWidth: "200px",
      maxWidth: "210px",
      selector: (row) => row.state,
    },
    {
      name: "City",
      minWidth: "200px",
      maxWidth: "210px",
      selector: (row) => row.city,
    },
    {
      name: "Phone Number",
      minWidth: "200px",
      maxWidth: "210px",
      selector: (row) => row.phone,
    },
    {
      name: "Approval",
      minWidth: "200px",
      maxWidth: "210px",
      button: true,
      cell: (row) => <>
        {row?.approvalObj?.firstName ?
          <>
            {`${row?.approvalObj?.firstName},${row?.approvalObj?.lastName},${row?.approvalObj?.address}`}

          </>
          : "NA"
        }
      </>,
    },
    {
      name: "Action",
      minWidth: "200px",
      maxWidth: "210px",
      button: true,
      cell: (row) => <CustomButton isBtn ClickEvent={() => handleAddressApprove(row)} btntype="button" btnName="EDIT" />,
    },
  ];

  const address_data = [
    {
      id: "1",
      name: "XYZ",
      address: "112/6 XYZ",
      region: "Delhi, India",
      email: "XYZ@gmail.com",
      contact: "5665455423",
    },
  ];

  // ==============================================================================================

  return (
    <main>
      <section className="product-category">
        <div className="container-fluid p-0">
          <DashboardBox className="mb-5">
            <h5 className="blue-1 mb-4">Customer Profile</h5>
            <div className="row">
              <div className="col-12 col-md-5">
                <div className="customer-profile">
                  <img src={images.customer} alt="" />
                  <h6 className="blue-1 text-capitalize my-3">{customerData?.firstName}</h6>
                  <ul className="blue-1 fs-14">
                    <li>
                      <span className="fw-600">
                        Name <span>:</span>
                      </span>
                      {customerData?.firstName}
                    </li>
                    <li>
                      <span className="fw-600">
                        Email <span>:</span>
                      </span>
                      {customerData?.email}
                    </li>
                    <li>
                      <span className="fw-600">
                        Phone <span>:</span>
                      </span>
                      {customerData?.phone}
                    </li>
                    <li
                    >
                      <span className="fw-600">
                        Registered Date <span>:</span>
                      </span>
                      {new Date(customerData?.createdAt).toDateString()}
                    </li>
                    <li>
                      <span className="fw-600">
                        DOB <span>:</span>
                      </span>
                      {new Date(customerData?.dob).toDateString()}
                    </li>
                    <li>
                      <span className="fw-600">
                        Active Status <span>:</span>
                      </span>
                      <CustomButton greenBtn btnName="Active" />
                    </li>
                    <li>
                      <span className="fw-600">
                        Kyc Status <span>:</span>
                      </span>
                      {customerData?.kycStatus}
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-12 col-md-7 row d-flex justify-content-between">
                <div className="col-12 col-md-3">
                  {/* <div className="customer-profile bg-light border-3 border-start border-dark border-opacity-50 p-4"> */}
                  <h5 className="blue-1 text-capitalize mb-3">Online Portal </h5>
                  <a target="_blank" href={generateFilePath(customerData?.onlinePortal)}>
                    <img src={generateFilePath(customerData?.onlinePortal)} style={{ width: 150 }} alt={customerData?.firstName} />
                  </a>
                </div>
                <div className="col-12 col-md-3 ml-2">
                  <h5 className="blue-1 text-capitalize mb-3">Visiting Card</h5>
                  <a target="_blank" href={generateFilePath(customerData?.visitingCard)}>
                    <img src={generateFilePath(customerData?.visitingCard)} style={{ width: 150 }} alt={customerData?.firstName} />
                  </a>
                </div>
                <div className="col-12 col-md-3 ml-2 mr-2">
                  <h5 className="blue-1 text-capitalize mb-3">Shop Image</h5>
                  <a target="_blank" href={generateFilePath(customerData?.shopImage)}>
                    <img src={generateFilePath(customerData?.shopImage)} style={{ width: 150 }} alt={customerData?.firstName} />
                  </a>
                </div>
              </div>
            </div>
          </DashboardBox>
          <ul className="nav nav-pills dashboard-pills mb-3" id="pills-tab" role="tablist">
            {tabList.map((item, i) => {
              return (
                <li key={i}>
                  <CustomButton
                    navPills
                    btnName={item.tabName}
                    pillActive={item.active ? true : false}
                    ClickEvent={() => {
                      tabClick(i, tabList, settabList);
                    }}
                  />
                </li>
              );
            })}
          </ul>
          <DashboardBox>
            {tabList.map((item) => {
              if (item.active) {
                if (item.tabName === "ORDERS") {
                  return (
                    <>
                      <DashboardTable>
                        <DataTable columns={table_columns} data={customerData?.orderArr} pagination />
                      </DashboardTable>
                    </>
                  );
                }

                if (item.tabName === "ADDRESSES") {
                  return (
                    <>
                      <DashboardTable>
                        <DataTable columns={address_columns} data={customerData?.addressArr} pagination />
                      </DashboardTable>
                    </>
                  );
                }
              }
            })}
          </DashboardBox>
        </div>
      </section>
    </main>
  );
}

export default CustomerDetail;
