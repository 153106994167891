import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FAQAdd, FAQDelete, FAQGet, FAQUpdate, SetFAQObj } from "../../../redux/actions/Faq/Faq.actions";
import ActionIcon from "../../Utility/ActionIcon";
import CustomButton from "../../Utility/Button";
import { DashboardBox, DashboardTable } from "../../Utility/DashboardBox";
function Faqs() {
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const faqArr = useSelector((state) => state.Faq.faqs);
  const faqObj = useSelector((state) => state.Faq.faqObj);
  const [isUpdate, setIsUpdate] = useState(false);

  const handleEditFaq = (row) => {
    console.log(row);
    dispatch(SetFAQObj(row));
  };

  const handleDeleteFaq = (id) => {
    dispatch(FAQDelete(id));
    handleGet();
  };

  const tag_columns = [
    {
      name: "ID",
      cell: (row, index) => index + 1,
      sortable: false,
    },
    {
      name: "Question",
      selector: (row) => row.question,
      sortable: true,
    },
    {
      name: "Answer",
      cell: (row) => `${row?.answer.slice(0, 50)}...`,
    },
    {
      name: "Action",
      minWidth: "210px",
      maxWidth: "211px",
      cell: (row) => <ActionIcon Uniquekey={row.id} remove edit isRedirected={true} deletePath="/SetUp/Faq" onDeleteClick={() => handleDeleteFaq(row?._id)} editPath="/SetUp/Faq" onEditClick={() => handleEditFaq(row)} />,
    },
  ];

  const handleFaqAdd = async () => {
    if (isUpdate) {
      let obj = { question, answer };
      dispatch(FAQUpdate(obj, faqObj?._id));
      dispatch(SetFAQObj(null));
      // navigate(-1)
    } else {
      let obj = { question, answer };
      dispatch(FAQAdd(obj));
      // navigate(-1)
    }
    setIsUpdate(false);
    setQuestion("");
    setAnswer("");
    handleGet();
  };

  const handleGet = () => {
    dispatch(FAQGet());
  };

  useEffect(() => {
    handleGet();
  }, []);

  useEffect(() => {
    if (faqObj) {
      setQuestion(faqObj?.question);
      setAnswer(faqObj?.answer);
      setIsUpdate(true);
    }
  }, [faqObj]);

  return (
    <main>
      <section className="product-category">
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-12 col-md-4">
              <h5 className="blue-1 mb-4">{isUpdate ? "Update" : "Add"} Faq</h5>
              <DashboardBox>
                <form action="#" className="form row">
                  <div className="col-12">
                    <label className="blue-1 fs-12">
                      Question <span className="red">*</span>
                    </label>
                    <input type="text" value={question} onChange={(e) => setQuestion(e.target.value)} className="form-control" />
                  </div>
                  <div className="col-12">
                    <label className="blue-1 fs-12">
                      Answer <span className="red">*</span>
                    </label>
                    <textarea value={answer} onChange={(e) => setAnswer(e.target.value)} type="text" className="form-control" />
                  </div>
                  <div className="col-12 mt-2">
                    <CustomButton btntype="button" ClickEvent={() => handleFaqAdd()} isBtn iconName="fa-solid fa-check" btnName="Save" />
                  </div>
                </form>
              </DashboardBox>
            </div>
            <div className="col-12 col-md-8">
              <div className="d-flex gap-3 justify-content-between mb-3 align-items-center">
                <h5 className="blue-1 m-0">Faqs List</h5>
                {/* <SearchBox extraClass="bg-white" /> */}
              </div>
              <DashboardTable>
                <DataTable columns={tag_columns} data={faqArr && faqArr.length ? faqArr : []} pagination />
              </DashboardTable>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default Faqs;
