import { toastError, toastSuccess } from "../../../components/Utility/ToastUtils";
import * as Coupons from "../../actions/Coupons/Coupons.actions";

const initialState = {
  coupons: null,
  couponObj: null,
  loading: false,
  error: null,
};

export const CouponReducer = (state = initialState, action) => {
  switch (action.type) {
    case Coupons.ADD_COUPONS:
      return {
        ...state,
        loading: true,
      };
    case Coupons.ADD_COUPONS_SUCCESS:
      toastSuccess(action.payload);

      return {
        ...state,
        loading: false,
      };
    case Coupons.ADD_COUPONS_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case Coupons.GET_ALL_COUPONS:
      return {
        ...state,
        loading: true,
      };
    case Coupons.GET_ALL_COUPONS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        coupons: action.payload.data,
      };
    case Coupons.GET_ALL_COUPONS_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case Coupons.DELETE_COUPON_BY_ID:
      return {
        ...state,
        loading: true,
      };
    case Coupons.DELETE_COUPON_BY_ID_SUCCESS:
      toastSuccess(action.payload.message);
      return {
        ...state,
        loading: false,
        error: null,
      };
    case Coupons.DELETE_COUPON_BY_ID_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case Coupons.UPDATE_COUPON_BY_ID:
      return {
        ...state,
        loading: true,
      };
    case Coupons.UPDATE_COUPON_BY_ID_SUCCESS:
      toastSuccess(action.payload);
      return {
        ...state,
        loading: false,
        error: null,
      };
    case Coupons.UPDATE_COUPON_BY_ID_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case Coupons.SET_COUPON_OBJ:
      return {
        ...state,
        loading: true,
      };
    case Coupons.SET_COUPON_OBJ_SUCCESS:
      toastSuccess(action.payload.message);
      return {
        ...state,
        couponObj: action.payload,
        loading: false,
        error: null,
      };
    case Coupons.SET_COUPON_OBJ_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
