import axios from "axios";
import { url } from "./url.service";

const serverUrl = url + "/product";

export const addProduct = (formData) => {
  return axios.post(serverUrl + "/addProduct", formData);
};

export const getProducts = (query) => {
  return axios.get(`${serverUrl}/getProducts?${query}`);
};
export const getProductsForAdmin = (query) => {
  return axios.get(`${serverUrl}/getProductsForAdmin?${query}`);
};

export const getById = async (id) => {
  return axios.get(`${serverUrl}/getById/${id}`);
};

export const deleteProductById = (id) => {
  return axios.delete(`${serverUrl}/deleteById/${id}`);
};

export const updateProductById = (formData, id) => {
  return axios.patch(`${serverUrl}/updateById/${id}`, formData);
};
export const updateProductStatusById = (id, formData) => {
  return axios.patch(`${serverUrl}/updateProductStatusById/${id}`, formData);
};



export const getCategoryWiseStock = (query) => {
  return axios.get(`${serverUrl}/getCategoryWiseStock?${query}`);
};

export const categoryWiseProductStock = (query, id) => {
  return axios.get(`${serverUrl}/categoryWiseProductStock/${id}?${query}`)
}

export const downloadExcel = async (query) => {
  return axios.get(`${serverUrl}/downloadExcelForProducts?${query}`);
};