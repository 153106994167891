import * as SupportService from "../../../services/Support.service";

export const GET_ALL_SUPPORT_TICKETS = "GET_ALL_SUPPORT_TICKETS";
export const GET_ALL_SUPPORT_TICKETS_SUCCESS = "GET_ALL_SUPPORT_TICKETS_SUCCESS";
export const GET_ALL_SUPPORT_TICKETS_FAIL = "GET_ALL_SUPPORT_TICKETS_FAIL";

export const GET_MESSAGES_BY_TICKET_ID = "GET_MESSAGES_BY_TICKET_ID";
export const GET_MESSAGES_BY_TICKET_ID_SUCCESS = "GET_MESSAGES_BY_TICKET_ID_SUCCESS";
export const GET_MESSAGES_BY_TICKET_ID_FAIL = "GET_MESSAGES_BY_TICKET_ID_FAIL";

export const CLOSE_TICKET_BY_TICKET_ID = "CLOSE_TICKET_BY_TICKET_ID";
export const CLOSE_TICKET_BY_TICKET_ID_SUCCESS = "CLOSE_TICKET_BY_TICKET_ID_SUCCESS";
export const CLOSE_TICKET_BY_TICKET_ID_FAIL = "CLOSE_TICKET_BY_TICKET_ID_FAIL";

export const SEND_MESSAGE_IN_CHAT_BY_ID = "SEND_MESSAGE_IN_CHAT_BY_ID";
export const SEND_MESSAGE_IN_CHAT_BY_ID_SUCCESS = "SEND_MESSAGE_IN_CHAT_BY_ID_SUCCESS";
export const SEND_MESSAGE_IN_CHAT_BY_ID_FAIL = "SEND_MESSAGE_IN_CHAT_BY_ID_FAIL";

export const getSupportTickets = (formData) => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_SUPPORT_TICKETS });
    let { data: response } = await SupportService.getAllSupportTicket(formData);
    if (response) {
      dispatch({
        type: GET_ALL_SUPPORT_TICKETS_SUCCESS,
        payload: { data: response.data, message: response.message },
      });
    }
  } catch (err) {
    console.error(err);
    dispatch({ type: GET_ALL_SUPPORT_TICKETS_FAIL, payload: err });
  }
};

export const getMessagesByTicket = (formData) => async (dispatch) => {
  try {
    dispatch({ type: GET_MESSAGES_BY_TICKET_ID });
    let { data: response } = await SupportService.getMessagesByTicketId(formData);
    if (response) {
      dispatch({
        type: GET_MESSAGES_BY_TICKET_ID_SUCCESS,
        payload: { data: response.data, supportObj: response.supportObj, message: response.message },
      });
    }
  } catch (err) {
    console.error(err);
    dispatch({ type: GET_MESSAGES_BY_TICKET_ID_FAIL, payload: err });
  }
};

export const closeTicketById = (formData) => async (dispatch) => {
  try {
    dispatch({ type: CLOSE_TICKET_BY_TICKET_ID });
    let { data: response } = await SupportService.closeSupportTicketByTicketId(formData);
    if (response) {
      dispatch({
        type: CLOSE_TICKET_BY_TICKET_ID_SUCCESS,
        payload: { message: response.message },
      });
    }
  } catch (err) {
    console.error(err);
    dispatch({ type: CLOSE_TICKET_BY_TICKET_ID_FAIL, payload: err });
  }
};

export const sendMessageInChatById = (formData) => async (dispatch) => {
  try {
    dispatch({ type: SEND_MESSAGE_IN_CHAT_BY_ID });
    let { data: response } = await SupportService.sendMessageInSupport(formData);
    if (response) {
      dispatch({
        type: SEND_MESSAGE_IN_CHAT_BY_ID_SUCCESS,
        payload: { message: response.message },
      });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: SEND_MESSAGE_IN_CHAT_BY_ID_FAIL, payload: error });
  }
};
