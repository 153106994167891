import axios from "axios";
import { url } from "./url.service";

const serverUrl = url + "/GiftCard";

export const addGiftCard = (formData) => {
  return axios.post(serverUrl + "/createGiftCard", formData);
};

export const getAllGiftCard = () => {
  return axios.get(`${serverUrl}/getAllGiftCard`);
};

export const deleteGiftCardById = (id) => {
  return axios.patch(`${serverUrl}/deleteGiftCardById/${id}`);
};

export const updateGiftCardById = (id, formData) => {
  return axios.patch(`${serverUrl}/updateGiftCardById/${id}`, formData);
};
