import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { getAllOrders } from "../../../services/order.service";
import ActionIcon from "../../Utility/ActionIcon";
import CustomButton from "../../Utility/Button";
import { DashboardTable } from "../../Utility/DashboardBox";
import SearchBox from "../../Utility/SearchBox";
function ReturnOrder({ name }) {
  const [orders, setOrders] = useState([]);
  const [searchStr, setSearchStr] = useState("");

  const getOrder = async () => {
    try {
      const { data: res } = await getAllOrders();
      if (res) {
        setOrders(res.data.filter((el) => el.isReturn));
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getOrder();
  }, []);

  const PendingOrder_columns = [
    {
      name: "SL",
      selector: (row, index) => index + 1,
      sortable: true,
    },
    {
      name: "Date",
      selector: (row) => new Date(row?.createdAt).toDateString(),
      sortable: true,
      searchable: true,
    },
    {
      name: "Order ID",
      selector: (row) => row?._id,
      sortable: true,
      searchable: true,
    },
    {
      name: "Phone",
      selector: (row) => row?.addressObj?.phone,
      sortable: true,
      searchable: true,
    },
    {
      name: "Total Product QTY",
      selector: (row) => row?.items?.reduce((acc, el) => acc + el.quantity, 0),
      sortable: true,
      searchable: true,
    },
    {
      name: "Total Amount",
      selector: (row) => row.totalAmount,
      sortable: true,
      searchable: true,
    },
    {
      name: "Shipment Id",
      selector: (row) => row?.returnObj?.returnShipmentId,
      sortable: true,
      searchable: true,
    },
    {
      name: "Order Status",
      button: true,
      width: "10%",
      cell: (row) => <CustomButton redBtn={row?.status == "CANCELLED"} greenBtn={row?.status != "CANCELLED"} btnName={row?.status} />,
      selector: (row) => row?.status,
      sortable: true,
      searchable: true,
    },
    {
      name: "Is Paid",
      button: true,
      width: "10%",

      selector: (row) => (row?.paymentObj?.paymentChk == 1 || row?.paymentObj?.paymentChk == 4 ? "PAID" : "PENDING"),
      cell: (row) => (
        <CustomButton
          redBtn={row?.paymentObj?.paymentChk != 1 && row?.paymentObj?.paymentChk != 4}
          greenBtn={row?.paymentObj?.paymentChk == 1 || row?.paymentObj?.paymentChk == 4}
          btnName={row?.paymentObj?.paymentChk == 1 || row?.paymentObj?.paymentChk == 4 ? "PAID" : "PENDING"}
        />
      ),
      sortable: true,
      searchable: true,
    },
    {
      name: "Action",
      cell: (row) => <CustomButton btntype="button" path={`/Order/Return-Detail?orderId=${row?._id}`} greenBtn btnName={"View"} />,
    },
  ];

  function filter(el) {
    let chk = false;
    let tempColumns = [...PendingOrder_columns.filter((el) => el?.searchable)];
    // console.log(tempColumns, "@@S");
    if (!tempColumns?.length) chk = true;
    tempColumns.forEach((elx) => {
      let val = elx?.selector(el);
      if (val) {
        if (typeof val == "string" || typeof val == "number") {
          if (`${val}`.toLowerCase().includes(searchStr.toLowerCase())) chk = true;
        }
      }
    });
    return chk;
  }

  const handleChange = (value) => {
    setSearchStr(value);
  };

  return (
    <DashboardTable className="mt-4">
      <div className="d-flex gap-3 justify-content-between mb-4">
        <h5 className="blue-1 m-0">{name}</h5>
        <SearchBox handleChange={handleChange} extraClass="bg-white" />
      </div>
      <DataTable columns={PendingOrder_columns} data={orders?.filter((el) => (searchStr ? filter(el) : true))} pagination />
    </DashboardTable>
  );
}

export default ReturnOrder;
