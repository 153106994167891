import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { SetBANNERObj } from "../../redux/actions/Banner/Banner.actions";
import { couponAdd, couponUpdateById } from "../../redux/actions/Coupons/Coupons.actions";
import CustomButton from "../Utility/Button";
import { generalModelStatuses } from "../Utility/constants";
import { DashboardBox } from "../Utility/DashboardBox";
import { CouponTypes } from "../Utility/utils";
function AddCoupons() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [code, setCode] = useState("");
  const [validity, setValidity] = useState(null);
  const [couponType, setCouponType] = useState({ label: CouponTypes.flat_off, value: CouponTypes.flat_off });
  const [couponTypeArr, setCouponTypeArr] = useState([
    { label: CouponTypes.percentage_off, value: CouponTypes.percentage_off },
    { label: CouponTypes.flat_off, value: CouponTypes.flat_off },
  ]);
  const [minimumOrderAmount, setMinimumOrderAmount] = useState(0);
  const [discountValue, setDiscountValue] = useState(0);

  const [description, setDescription] = useState("");
  const [url, setUrl] = useState("");
  const [imageStr, setImageStr] = useState("");
  const [isUpdateBanner, setIsUpdateBanner] = useState(false);
  const [selectedCouponId, setselectedCouponId] = useState("");
  const [prevImage, setPrevImage] = useState("");
  const [status, setStatus] = useState(generalModelStatuses.PENDING);
  const couponObj = useSelector((state) => state.coupon.couponObj);

  useEffect(() => {
    if (couponObj) {
      setselectedCouponId(couponObj._id);
      setName(couponObj.name);
      setCode(couponObj.code);
      setValidity(couponObj?.validity)
      setDiscountValue(couponObj.discountValue);
      setMinimumOrderAmount(couponObj.minimumOrderAmount);
      if (couponObj?.couponType == CouponTypes.flat_off) {
        setCouponType({ label: CouponTypes.flat_off, value: CouponTypes.flat_off });
      } else {
        setCouponType({ label: CouponTypes.percentage_off, value: CouponTypes.percentage_off });
      }
      setIsUpdateBanner(true);
    }
    return () => {
      dispatch(SetBANNERObj(null));
    };
  }, [couponObj]);

  const handleSubmit = () => {
    let obj = {
      name,
      code,
      validity,
      minimumOrderAmount,
      couponType: couponType.value,
      discountValue,
    };
    if (isUpdateBanner) {
      dispatch(couponUpdateById(selectedCouponId, obj));
    } else {
      dispatch(couponAdd(obj));
    }
    navigate(-1)
  };
  return (
    <main>
      <section className="product-category">
        <div className="container-fluid p-0">
          <h5 className="blue-1 mb-4">{isUpdateBanner ? "Update" : "Add New "} Coupon</h5>
          <form action="#" className="form">
            <div className="row">
              <div className="col-12 col-md-8 mb-0">
                <DashboardBox>
                  <div className="row">
                    {/* <h5 className="blue-1 mb-4">Coupon Information</h5> */}
                    <div className="col-12">
                      <label>
                        Name <span className="red">*</span>
                      </label>
                      <input value={name} onChange={(event) => setName(event.target.value)} type="text" className="form-control" />
                    </div>
                    <div className="col-12">
                      <label>
                        Valid Till <span className="red">*</span>
                      </label>
                      <input value={validity} onChange={(event) => setValidity(event.target.value)} type="date" className="form-control" />
                    </div>

                    <div className="col-12">
                      <label>
                        Code <span className="red">*</span>
                      </label>
                      <input value={code} onChange={(event) => setCode(event.target.value)} type="text" className="form-control" />
                    </div>
                    <div className="col-12">
                      <label>Coupon Type</label>
                      <Select onChange={(e) => setCouponType(e)} value={couponType} options={couponTypeArr} />
                    </div>

                    <div className="col-12">
                      <label>Discount Value</label>
                      <input value={discountValue} onChange={(event) => setDiscountValue(event.target.value)} type="number" className="form-control" />
                    </div>
                    <div className="col-12">
                      <label>Minimum Order Amount</label>
                      <input value={minimumOrderAmount} onChange={(event) => setMinimumOrderAmount(event.target.value)} type="number" className="form-control" />
                    </div>
                  </div>
                  <div className="col-12 mt-2">
                    <CustomButton btntype="button" ClickEvent={handleSubmit} isBtn iconName="fa-solid fa-check" btnName="Save" />
                  </div>
                </DashboardBox>
              </div>
            </div>
          </form>
        </div>
      </section>
    </main>
  );
}

export default AddCoupons;
