import React, { useEffect } from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { couponDeleteById, couponGet, setCouponObj } from "../../redux/actions/Coupons/Coupons.actions";
import ActionIcon from "../Utility/ActionIcon";
import CustomButton from "../Utility/Button";
import { DashboardTable } from "../Utility/DashboardBox";
import SearchBox from "../Utility/SearchBox";
import { CouponTypes } from "../Utility/utils";

function ViewCoupons() {
  const dispatch = useDispatch();

  const bannerArr = useSelector((state) => state.coupon.coupons);

  useEffect(() => {
    dispatch(couponGet());
  }, []);

  const handleEdit = (row) => {
    dispatch(setCouponObj(row));
  };

  const handleDelete = (id) => {
    if (window.confirm("Do you really want to delete this coupon?")) {
      dispatch(couponDeleteById(id));
    }
  };
  const brand_columns = [
    {
      name: "ID",
      selector: (row, index) => index + 1,
      sortable: true,
      width: "5%",
    },
    {
      name: "Name",
      selector: (row) => row.name,
      width: "10%",
    },
    {
      name: "Code",
      selector: (row) => row.code,
      width: "10%",
    },
    {
      name: "Discount Type",
      selector: (row) => row.couponType,
      width: "20%",
    },
    {
      name: "Discount Amount",
      cell: (row) => (row.couponType == CouponTypes.flat_off ? `₹ ${row.discountValue}` : `${row.discountValue} %`),
      width: "20%",
    },
    {
      name: "Minimum Order Value",
      selector: (row) => `₹ ${row.minimumOrderAmount}`,
      width: "20%",
    },
    // {
    //   name: "Status",
    //   button: true,
    //   width: "20%",
    //   cell: (row) => <CustomButton greenBtn btnName="Active" />,
    // },
    {
      name: "Action",
      width: "20%",
      cell: (row) => <ActionIcon isRedirected={true} onEditClick={() => handleEdit(row)} editPath="/AddCoupons" onDeleteClick={() => handleDelete(row?._id)} deletePath="/ViewCoupons" remove edit Uniquekey={row._id} />,
    },
  ];

  return (
    <main>
      <section className="product-category">
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-12">
              <div className="d-flex align-items-center justify-content-between mb-3">
                <h5 className="blue-1 m-0">Coupons List</h5>
                <div className="d-flex align-items-center gap-3">
                  <CustomButton isLink iconName="fa-solid fa-plus" btnName="ADD NEW COUPON" path="/AddCoupons" small roundedPill />
                  {/* <SearchBox extraClass="bg-white" /> */}
                </div>
              </div>
              <DashboardTable>
                <DataTable columns={brand_columns} data={bannerArr && bannerArr.length > 0 ? bannerArr : []} pagination />
              </DashboardTable>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default ViewCoupons;
