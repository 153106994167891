import React from "react";
import DataTable from "react-data-table-component";
import Select from "react-select";
import { images } from "../Images/Images";
import ActionIcon from "../Utility/ActionIcon";
import CustomButton from "../Utility/Button";
import { DashboardBox, DashboardTable } from "../Utility/DashboardBox";
import FileUpload from "../Utility/FileUpload";
import SearchBox from "../Utility/SearchBox";

function BlogCategory() {
  const options = [
    { value: "chocolate", label: "Chocolate" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" },
  ];
  const blog_category_columns = [
    {
      name: "SL",
      selector: (row) => row.Num,
      sortable: true,
    },
    {
      name: "IMAGE",
      cell: (row) => <img height="84px" width="56px" alt={row.category} src={row.img} />,
    },
    {
      name: "Category",
      selector: (row) => row.category,
    },
    {
      name: "Action",
      cell: (row) => <ActionIcon remove edit Uniquekey={row.id} />,
    },
  ];

  const blog_category_data = [];

  return (
    <main>
      <section className="product-category">
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-12 col-md-4">
              <h5 className="blue-1 mb-4">Add Notification</h5>
              <DashboardBox>
                <form action="#" className="form row">
                  <div className="col-12 mb-3">
                    <label className="blue-1 fs-12">
                      Title<span className="red">*</span>
                    </label>
                    <input type="text" className="form-control" />
                  </div>

                  <div className="col-12 mb-3">
                    <label className="blue-1 fs-12">
                      Description<span className="red">*</span>
                    </label>
                    <input type="text" className="form-control" />
                  </div>
                  <div className="col-12">
                    <CustomButton isBtn iconName="fa-solid fa-check" btnName="ADD" />
                  </div>
                </form>
              </DashboardBox>
            </div>
            <div className="col-12 col-md-8">
              <div className="d-flex justify-content-between mb-2 align-items-center">
                <h5 className="blue-1 m-0">Notification List</h5>
                <SearchBox extraClass="bg-white" />
              </div>
              <DashboardTable>
                <DataTable columns={blog_category_columns} data={blog_category_data} pagination />
              </DashboardTable>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default BlogCategory;
