import isBase64 from "is-base64";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import Select from "react-select";
import { ATTRIBUTEGet } from "../../../redux/actions/Attribute/Attribute.actions";
import { BrandGet } from "../../../redux/actions/Brand/brand.actions";
import { getAllNestedCategories } from "../../../redux/actions/Category/Category.actions";
import { PRODUCTAdd, PRODUCTUpdate } from "../../../redux/actions/Product/Product.actions";
import CustomButton from "../../Utility/Button";
import { DashboardBox } from "../../Utility/DashboardBox";
import FileUpload from "../../Utility/FileUpload";
import { AddModal } from "../../Utility/Modal";
import { generateFilePath } from "../../Utility/utils";
import { toastError } from "../../Utility/ToastUtils";
import { getById } from "../../../services/product.service";

function GeneralProduct() {
  const [searchParams, setSearchParams] = useSearchParams('')
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [sku, setSku] = useState("");
  const [mainCategoryArr, setMainCategoryArr] = useState([]);
  const [subCategoryArr, setSubCategoryArr] = useState([]);
  const [modelNumber, setModelNumber] = useState("");
  const [minimumOrderQuantity, setMinimumOrderQuantity] = useState("");
  const [maximumOrderQuantity, setMaximumOrderQuantity] = useState("");
  const [selectedBrandId, setSelectedBrandId] = useState("");
  const [tags, setTags] = useState("");
  const [dimension_width, setDimension_width] = useState(0);
  const [dimension_height, setDimension_height] = useState(0);
  const [dimension_length, setDimension_length] = useState(0);
  const [dimension_weight, setDimension_weight] = useState(0);
  const [additionalShippingCharge, setAdditionalShippingCharge] = useState(0);
  const [stock, setStock] = useState(0);
  const [sellingPrice, setSellingPrice] = useState(0);
  const [discountValue, setDiscountValue] = useState(0);
  const [gst, setgst] = useState("");
  const [selectedCategoryId, setSelectedCategoryId] = useState("");
  const [selectedDiscountType, setSelectedDiscountType] = useState("");
  const [despcription, setdespcription] = useState("");
  const [Specifications, setSpecifications] = useState("");
  const [ModalBox, setModalBox] = useState(false);
  const [ModalType, setModalType] = useState("");
  const [ModalName, setModalName] = useState("");
  const [isBestSeller, setIsBestSeller] = useState(false);
  const [isTrending, setIsTrending] = useState(false);
  const [mainCategoryObj, setMainCategoryObj] = useState({});
  const [subCategoryObj, setSubCategoryObj] = useState({});
  const [productImageStr, setProductImageStr] = useState("");
  const [specificationFile, setSpecificationFile] = useState("");

  const brands = useSelector((state) => state.brand.brands);
  const categoryArr = useSelector((state) => state.category.categories);
  const [mainAttributesArr, setMainAttributesArr] = useState([]);
  const [subAttributesArr, setSubAttributesArr] = useState([]);
  // const productObj = useSelector((state) => state.product.productObj);

  const [productObj, setProductObj] = useState(null)
  const attributes = useSelector((state) => state.attribute.attributes);
  const [prevProductId, setPrevProductId] = useState("");
  const [productImageArr, setProductImageArr] = useState([""]);
  useEffect(() => {
    dispatch(getAllNestedCategories());
    dispatch(BrandGet());
    dispatch(ATTRIBUTEGet());
    getProductByIdPrev()
  }, []);


  const getProductByIdPrev = async () => {
    try {
      if (searchParams.get("id")) {

        const { data: res } = await getById(searchParams.get("id"));
        if (res) {
          setProductObj(res.data)
        }
      }
    } catch (error) {
      toastError(error)
    }
  }


  useEffect(() => {
    if (categoryArr && categoryArr.length > 0) {
      setMainCategoryArr([...categoryArr]);
    }
  }, [categoryArr]);

  useEffect(() => {
    if (attributes && attributes.length > 0) {
      setMainAttributesArr([...attributes]);
    }
  }, [attributes]);

  const handleMainCategorySelection = (obj, value) => {
    // console.log(obj, "CATOBJ");
    if (value == "main") {
      setMainCategoryObj(obj);
    } else {
      setSubCategoryObj(obj);
    }
    if (obj?.subCategoryArr && obj?.subCategoryArr.length > 0) {
      setSubCategoryArr(obj.subCategoryArr);
    }
    setSellingPrice(obj?.price);
    setSelectedCategoryId(obj?._id);
  };

  const handleMainAttributeSelection = (obj) => {
    // console.log(obj);
    if (obj?.attributeValueArr && obj?.attributeValueArr.length > 0) {
      setSubAttributesArr(obj.attributeValueArr);
    }
  };

  const handleBrandSelection = (obj) => {
    // console.log(obj);
    setSelectedBrandId(obj);
  };

  const handleFileSet = (value, index) => {
    let arr = [...productImageArr];
    arr[index] = value;
    setProductImageArr([...arr]);
  };

  const handlePdfFileSet = (value) => {
    // console.log(value);
    setSpecificationFile(value);
  };

  const handleSubmit = () => {
    let obj = {
      name,
      sku,
      minimumOrderQuantity,
      maximumOrderQuantity,
      modelNumber,
      description: despcription,
      specification: Specifications,
      productImageStr,
      productImageArr,
      specificationFile,
      stock,
      weight: dimension_weight,
      height: dimension_height,
      width: dimension_width,
      length: dimension_length,
      sellingPrice: sellingPrice,
      discountValue,
      discountType: selectedDiscountType,
      categoryId: subCategoryObj?.value,
      isBestSeller,
      isTrending,
      parentCategoryIdArr: [{ categoryId: mainCategoryObj?.value }],
      brandId: selectedBrandId?.value,
    };
    if (prevProductId) {
      dispatch(PRODUCTUpdate(obj, prevProductId));
    } else {
      dispatch(PRODUCTAdd(obj));
    }
    navigate(-1);
    console.log(productImageArr);
  };

  const checkBase64 = (str) => {
    return isBase64(str, { allowMime: true });
  };



  useEffect(() => {
    if (productObj && productObj?._id && categoryArr && categoryArr.length > 0 && brands && brands.length > 0) {
      console.log(productObj, "PRODUCT OBJ");
      setName(productObj.name);
      setdespcription(productObj.despcription);
      setSpecifications(productObj.specification);
      setModelNumber(productObj.modelNumber);
      setSku(productObj.sku);
      setMinimumOrderQuantity(productObj.minimumOrderQuantity);
      setMaximumOrderQuantity(productObj.maximumOrderQuantity);
      setDimension_height(productObj.height);
      setDimension_length(productObj?.length);
      setDimension_weight(productObj.weight);
      setDimension_length(productObj.length);
      setDiscountValue(productObj.discountValue);
      setSellingPrice(productObj.sellingPrice);
      if (productObj?.isBestSeller) {
        setIsBestSeller(productObj?.isBestSeller);
      }
      if (productObj?.isTrending) {
        setIsTrending(productObj?.isTrending);
      }
      if (productObj?.parentCategoryIdArr?.length) {
        let tempParentCategoryObj = categoryArr?.find((el) => el._id == productObj?.parentCategoryIdArr[0]?.categoryId);
        if (tempParentCategoryObj) {
          setSubCategoryArr(tempParentCategoryObj?.subCategoryArr);
          setMainCategoryObj({ value: productObj?.parentCategoryIdArr[0]?.categoryId, label: tempParentCategoryObj?.name });
        }
      }
      if (productObj?.brandId) {
        let tempBrandObj = brands.find((el) => el._id == productObj?.brandId);
        if (tempBrandObj) {
          setSelectedBrandId({ label: tempBrandObj?.name, value: productObj?.brandId });
        }
      }
      if (productObj?.categoryId) {
        let tempParentCategoryObj = categoryArr.find((el) => el._id == productObj?.parentCategoryIdArr[0]?.categoryId);
        let tempCategoryObj = tempParentCategoryObj.subCategoryArr.find((el) => el._id == productObj?.categoryId);
        if (tempCategoryObj) {
          setSubCategoryObj({ value: productObj?.categoryId, label: tempCategoryObj?.name });
        }
      }
      console.log(productObj?.parentCategoryIdArr[0]?.categoryId, "CATEGORY");
      setPrevProductId(productObj?._id);
      if (productObj?.productImageArr) {
        setProductImageArr(productObj?.productImageArr);
      }
      setStock(productObj.stock);
    }
  }, [productObj, categoryArr, brands]);

  const handleProductImageAdd = () => {
    setProductImageArr([...productImageArr, ""]);
  };

  const handleDiscountTypeChange = (obj) => {
    setSelectedDiscountType(obj.value);
  };

  const options = [
    { value: "chocolate", label: "CGST" },
    { value: "strawberry", label: "IGST" },
    { value: "vanilla", label: "SGST" },
  ];

  const discount = [
    { value: "amount", label: "Amount" },
    { value: "percentage", label: "Percentage" },
  ];

  return (
    <form className="form">
      <div className="row">
        <div className="col-12 col-md-8">
          <DashboardBox>
            <div className="border-bottom pb-3 mb-4 row">
              <h5 className="blue-1 mb-4">Product Information</h5>

              <div className="col-12 col-md-6 mb-3">
                <label>
                  Name <span className="red">*</span>
                </label>
                <input value={name} onChange={(event) => setName(event.target.value)} type="text" className="form-control" />
              </div>
              <div className="col-12 col-md-6 mb-3">
                <label>
                  PRODUCT SKU <span className="red">*</span>
                </label>
                <input value={sku} onChange={(event) => setSku(event.target.value)} type="text" className="form-control" />
              </div>
              <div className="col-12 col-md-6 mb-3">
                <label>MODEL NUMBER</label>
                <input type="text" value={modelNumber} onChange={(event) => setModelNumber(event.target.value)} className="form-control" />
              </div>
              <div className="col-12 col-md-3 mb-3">
                <div className="d-flex align-items-baseline justify-content-between">
                  <label>
                    CATEGORY<span className="red">*</span>
                  </label>
                  <CustomButton
                    isBtn
                    iconName="fa-solid fa-circle-plus"
                    btnName="ADD NEW"
                    changeClass="green fs-12 border-0 bg-white"
                    ClickEvent={(e) => {
                      e.preventDefault();
                      setModalBox(true);
                      setModalType("addCategoryModal");
                      setModalName("Create Category");
                    }}
                  />
                  <AddModal ModalBox={ModalBox} setModalBox={setModalBox} name={ModalName} ModalType={ModalType} />
                </div>
                {mainCategoryArr && mainCategoryArr.length > 0 && <Select onChange={(e) => handleMainCategorySelection(e, "main")} value={mainCategoryObj} options={mainCategoryArr.map((el) => ({ ...el, label: el.name, value: el._id }))} />}
              </div>
              {subCategoryArr && subCategoryArr.length > 0 && (
                <div className="col-12 col-md-3 mb-3">
                  <div className="d-flex align-items-baseline justify-content-between">
                    <label>
                      Sub CATEGORY<span className="red">*</span>
                    </label>
                    <CustomButton
                      isBtn
                      iconName="fa-solid fa-circle-plus"
                      btnName="ADD NEW"
                      changeClass="green fs-12 border-0 bg-white"
                      ClickEvent={(e) => {
                        e.preventDefault();
                        setModalBox(true);
                        setModalType("addCategoryModal");
                        setModalName("Create Category");
                      }}
                    />
                    <AddModal ModalBox={ModalBox} setModalBox={setModalBox} name={ModalName} ModalType={ModalType} />
                  </div>
                  {subCategoryArr && subCategoryArr.length > 0 && <Select onChange={(e) => handleMainCategorySelection(e, "sub")} value={subCategoryObj} options={subCategoryArr.map((el) => ({ ...el, label: el.name, value: el._id }))} />}
                </div>
              )}

              <div className="col-12 col-md-3 mb-3">
                <div className="d-flex align-items-baseline justify-content-between">
                  <label>BRAND</label>
                  <CustomButton
                    isBtn
                    iconName="fa-solid fa-circle-plus"
                    btnName="ADD NEW"
                    changeClass="green fs-12 border-0 bg-white"
                    ClickEvent={(e) => {
                      e.preventDefault();
                      setModalBox(true);
                      setModalType("addBrandModal");
                      setModalName("Create Brand");
                    }}
                  />
                  <AddModal ModalBox={ModalBox} setModalBox={setModalBox} name={ModalName} ModalType={ModalType} />
                </div>
                {brands && brands.length > 0 && <Select onChange={handleBrandSelection} value={selectedBrandId} options={brands && brands.length > 0 ? brands.map((el) => ({ ...el, label: el.name, value: el._id })) : []} />}
              </div>

              <div className="col-12 col-md-4 mb-3">
                <label>
                  MINIMUM ORDER QTY <span className="red">*</span>
                </label>
                <input value={minimumOrderQuantity} onChange={(event) => setMinimumOrderQuantity(event.target.value)} type="number" className="form-control" />
              </div>
              <div className="col-12 col-md-4 mb-3">
                <label>MAX ORDER QTY</label>
                <input value={maximumOrderQuantity} onChange={(event) => setMaximumOrderQuantity(event.target.value)} type="number" className="form-control" />
              </div>
              {/* <div className="col-12 col-md-3 mb-3">
                <label>
                  Attributes<span className="red">*</span>
                </label>
                {mainAttributesArr && mainAttributesArr.length > 0 && <Select onChange={handleMainAttributeSelection} options={mainAttributesArr} />}
              </div>
              {subAttributesArr && subAttributesArr.length > 0 && (
                <div className="col-12 col-md-3 mb-3">
                  <div className="d-flex align-items-baseline justify-content-between">
                    <label>
                      Attribute Values<span className="red">*</span>
                    </label>
                  </div>
                  {subAttributesArr && subAttributesArr.length > 0 && <Select options={subAttributesArr} />}
                </div>
              )} */}
              <div className="col-12 mb-3">
                <label>
                  Tags (Comma Separated)
                  <span className="red">*</span>
                </label>
                <input value={tags} onChange={(event) => setTags(event.target.value)} type="text" className="form-control" />
              </div>
            </div>

            <div className="border-bottom pb-3 mb-4 row">
              <h5 className="blue-1 mb-4">Stock Information</h5>

              <>
                <div className="col-12 col-md-6 mb-3">
                  <label>PRODUCT STOCK</label>
                  <input type="number" min={0} value={stock} onChange={(event) => setStock(event.target.value)} className="form-control" />
                </div>
              </>
            </div>

            <div className="row">
              {/* <h5 className="blue-1 mb-4">SEO info</h5>
              <div className="col-12 mb-3">
                <label>META TITLE</label>
                <input type="text" className="form-control" />
              </div>
              <div className="col-12 mb-3">
                <label>META DESCRIPTION</label>
                <textarea name="META DESCRIPTION" className="form-control" rows="3"></textarea>
              </div>
              <div className="col-12 mb-3">
                <label>META IMAGE (300X300)PX</label>
                <FileUpload />
              </div> */}
              <div className="col-12">
                <CustomButton btntype="button" ClickEvent={handleSubmit} isBtn iconName="fa-solid fa-check" btnName="Save" />
              </div>
            </div>
          </DashboardBox>
        </div>
        <div className="col-12 col-md-4">
          <DashboardBox>
            <div className="border-bottom pb-3 mb-4 row">
              <h5 className="blue-1 mb-3">Product Image Info</h5>
              {productImageArr &&
                productImageArr.map((el, index) => {
                  return (
                    <div className="col-12 mb-3">
                      <label>
                        Product Image<span className="red">*</span>
                      </label>
                      <FileUpload index={index} onFileChange={handleFileSet} />
                      {checkBase64(el) ? <img src={el} height="50" width="50" /> : <img src={generateFilePath(el)} height="50" width="50" />}
                    </div>
                  );
                })}
              <div>
                <CustomButton btntype="button" ClickEvent={() => handleProductImageAdd()} isBtn iconName="fa-solid" btnName="+" />
                {/* <CustomButton btntype="button" ClickEvent={() => handleProductImageAdd()} isBtn iconName="fa-solid" btnName="-" /> */}
              </div>
            </div>
            <div className="border-bottom pb-3 mb-4 row">
              <h5 className="blue-1 mb-3">Pdf Specifications</h5>
              <div className="col-12 mb-3">
                <label>PDF SPECIFICATIONS</label>
                <FileUpload onFileChange={handlePdfFileSet} />
              </div>
            </div>
            {/* <div className="border-bottom pb-3 mb-4 row">
              <h5 className="blue-1 mb-3">Product Video Info</h5>
              <div className="col-12 mb-3">
                <label>VIDEO PROVIDER</label>
                <Select options={options} />
              </div>
              <div className="col-12 mb-3">
                <label>VIDEO LINK</label>
                <input type="url" className="form-control" />
              </div>
            </div> */}
            <div className="row">
              <h5 className="blue-1 mb-3">Others</h5>
              <div className="col-12 mb-3">
                <label>
                  Status<span className="red">*</span>
                </label>
                <div className="d-flex">
                  <div className="form-check form-check-inline d-flex align-items-center pointer">
                    <input className="form-check-input pointer" type="checkbox" name="product-status" checked={isBestSeller} onChange={() => setIsBestSeller(!isBestSeller)} id="product-publish" />
                    <label className="form-check-label fs-14 pointer" htmlFor="product-publish">
                      Is Best Seller ?
                    </label>
                  </div>
                  <div className="form-check form-check-inline d-flex align-items-center pointer">
                    <input className="form-check-input pointer" type="checkbox" name="product-status" checked={isTrending} onChange={() => setIsTrending(!isTrending)} id="product-pending" />
                    <label className="form-check-label fs-14 pointer" htmlFor="product-pending">
                      Is Trending ?
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </DashboardBox>
        </div>
      </div>
    </form>
  );
}

export default GeneralProduct;
