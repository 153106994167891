import { addTerms, deleteTerms, getTerms, updateTerms } from "../../../services/Terms.service";

export const TERM_ADD = "TERM_ADD";
export const TERM_ADD_SUCCESS = "TERM_ADD_SUCCESS";
export const TERM_ADD_FAIL = "TERM_ADD_FAIL";

export const GET_ALL_TERMS = "GET_ALL_TERMS";
export const GET_ALL_TERMS_SUCCESS = "GET_ALL_TERMS_SUCCESS";
export const GET_ALL_TERMS_FAIL = "GET_ALL_TERMS_FAIL";

export const UPDATE_TERM_BY_ID = "UPDATE_TERM_BY_ID";
export const UPDATE_TERM_BY_ID_SUCCESS = "UPDATE_TERM_BY_ID_SUCCESS";
export const UPDATE_TERM_BY_ID_FAIL = "UPDATE_TERM_BY_ID_FAIL";

export const SET_TERM_OBJ = "SET_TERM_OBJ";
export const SET_TERM_OBJ_SUCCESS = "SET_TERM_OBJ_SUCCESS";
export const SET_TERM_OBJ_FAIL = "SET_TERM_OBJ_FAIL";

export const DELETE_TERM_BY_ID = "DELETE_TERM_BY_ID";
export const DELETE_TERM_BY_ID_SUCCESS = "DELETE_TERM_BY_ID_SUCCESS";
export const DELETE_TERM_BY_ID_FAIL = "DELETE_TERM_BY_ID_FAIL";

export const TERMAdd = (formData) => async (dispatch) => {
  try {
    dispatch({ type: TERM_ADD });
    let { data: response } = await addTerms(formData);
    if (response) {
      console.log(response);
      dispatch({
        type: TERM_ADD_SUCCESS,
        payload: response.message,
      });
    }
  } catch (err) {
    console.error(err);
    dispatch({ type: TERM_ADD_FAIL, payload: err });
  }
};

export const TERMGet = () => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_TERMS });
    let { data: response } = await getTerms();
    if (response) {
      console.log(response);
      dispatch({
        type: GET_ALL_TERMS_SUCCESS,
        payload: { data: response.data, message: response.message },
      });
    }
  } catch (err) {
    console.error(err);
    dispatch({ type: TERM_ADD_FAIL, payload: err });
  }
};

export const SetTERMObj = (formData) => async (dispatch) => {
  try {
    console.log(formData);
    dispatch({ type: SET_TERM_OBJ });
    if (formData) {
      dispatch({
        type: SET_TERM_OBJ_SUCCESS,
        payload: { data: formData },
      });
    } else {
      dispatch({
        type: SET_TERM_OBJ_SUCCESS,
        payload: { data: null },
      });
    }
  } catch (err) {
    console.error(err);
    dispatch({ type: SET_TERM_OBJ_FAIL, payload: { message: "NOT FOUND" } });
  }
};

export const TERMUpdate = (formData, id) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_TERM_BY_ID });
    let { data: response } = await updateTerms(formData, id);
    if (response) {
      console.log(response);
      dispatch({
        type: UPDATE_TERM_BY_ID_SUCCESS,
        payload: { message: response.message },
      });
    }
  } catch (err) {
    console.error(err);
    dispatch({ type: UPDATE_TERM_BY_ID_FAIL, payload: err });
  }
};

export const TERMDelete = (id) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_TERM_BY_ID });
    let { data: response } = await deleteTerms(id);
    if (response) {
      console.log(response);
      dispatch({
        type: DELETE_TERM_BY_ID_SUCCESS,
        payload: { message: response.message },
      });
      TERMGet();
    }
  } catch (err) {
    console.error(err);
    dispatch({ type: DELETE_TERM_BY_ID_FAIL, payload: err });
  }
};
