import { Switch } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { PRODUCTDelete, PRODUCTGet, SetPRODUCTObj } from "../../redux/actions/Product/Product.actions";
import { getProductsForAdmin, updateProductStatusById } from "../../services/product.service";
import { url } from "../../services/url.service";
import ActionIcon from "../Utility/ActionIcon";
import CustomButton from "../Utility/Button";
import { DashboardTable } from "../Utility/DashboardBox";
import { AddModal } from "../Utility/Modal";
import { toastError, toastSuccess } from "../Utility/ToastUtils";
import { generateFilePath } from "../Utility/utils";
import { debounce } from "lodash";
import Select from "react-select";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { getNestedCategories } from "../../services/category.service";
import SearchBox from "../Utility/SearchBox";
function ProductList() {
  const dispatch = useDispatch();

  // const productArr = useSelector((state) => state.product.products);

  const [categoryArr, setCategoryArr] = useState([]);
  const [selectedCategoryObj, setSelectedCategoryObj] = useState(null);
  const [selectedLastCategoryObj, setSelectedLastCategoryObj] = useState(null);
  const [totalElements, setTotalElements] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [productsArr, setProductsArr] = useState([]);
  const [productPdfModal, setProductPdfModal] = useState(false);
  const [skipDownload, setSkipDownload] = useState(0)
  const [limitDownload, setLimitDownload] = useState(400)
  const [searchStr, setSearchStr] = useState('');

  // useEffect(() => {
  //   if (productArr && productArr.length > 0) {
  //     setProductsArr([...productArr.map((el) => ({ ...el, checked: false }))]);
  //   }
  // }, [productArr]);
  useEffect(() => {
    // dispatch(PRODUCTGet());
    getProductsData(page,
      limit, "")
    getCategories();
  }, []);

  const getCategories = async () => {
    try {
      let { data: res } = await getNestedCategories();
      if (res) {
        console.log(res.data);
        setCategoryArr(res.data);
      }
    } catch (error) {
      toastError(error);
    }
  };

  const handleUpdateStatus = async (id, value) => {
    try {
      let { data: res } = await updateProductStatusById(id, { active: value });
      if (res.message) {
        toastSuccess(res.message);
        dispatch(PRODUCTGet());
      }
    } catch (err) {
      toastError(err);
    }
  };

  const handleEdit = (row) => {
    dispatch(SetPRODUCTObj(row));
  };

  const handleDeleteById = (id) => {
    dispatch(PRODUCTDelete(id));
  };

  const [ModalType, setModalType] = useState("");
  const [ModalName, setModalName] = useState("");
  const [ModalBox, setModalBox] = useState(false);

  const getProductsData = async (skipValue,
    limitValue, searchValue) => {
    try {
      let query = `skip=${skipValue}&limit=${limitValue}&searchValue=${searchValue}`;


      console.log(query, "query")
      const { data: res } = await getProductsForAdmin(query);
      if (res) {
        // console.log(res.data)
        setTotalElements(res.totalElements)
        setProductsArr([...res.data.map((el) => ({ ...el, checked: false }))]);
      }
    } catch (error) {
      toastError(error)
    }

  }

  const product_sale_columns = [
    {
      name: "SL",
      selector: (row, index) => index + 1,
      sortable: true,
      width: "5%",
    },
    {
      name: "Name",
      selector: (row) => row.name,
      width: "15%",
      searchable: true,
    },
    {
      name: "SKU",
      selector: (row) => row.sku,
      width: "15%",
      searchable: true,

    },
    {
      name: "Model Number",
      selector: (row) => row.modelNumber,
      width: "15%",
      searchable: true,

    },
    {
      name: "Category",
      selector: (row) => row?.categoryObj?.name,
      width: "15%",
      searchable: true,

    },
    {
      name: "Image",
      grow: 0,
      cell: (row) => <img height="84px" width="56px" alt={row.Name} src={generateFilePath(row.productImage)} />,
      width: "15%",
    },
    {
      name: "Stock",
      grow: 0,
      selector: (row) => row.stock,
      width: "10%",
    },
    {
      name: "Status",
      button: true,
      selector: (row) => <Switch checked={row.active} onChange={(e) => handleUpdateStatus(row._id, e.target.checked)} />,
      width: "10%",
    },
    {
      name: "Action",
      width: "15%",
      cell: (row) => (
        <>
          <ActionIcon
            Uniquekey={row.id}
            remove
            edit
            deletePath="/Product/Product-List"
            onDeleteClick={() => handleDeleteById(row._id)}
            isRedirected={true}
            onEditClick={() => handleEdit(row)}
            editPath={`/Product/AddProduct?id=${row?._id}`}
          />

          <AddModal ModalBox={ModalBox} setModalBox={setModalBox} name={ModalName} ModalType={ModalType} />
        </>
      ),
    },
  ];
  const handleProductPdfRedirect = (type) => {
    console.log(selectedCategoryObj, selectedLastCategoryObj);
    if (selectedCategoryObj && selectedLastCategoryObj) {
      window.open(`${url}/product/generateProductPdf?type=${type}&categoryId=${selectedLastCategoryObj?._id}&lastCategoryId=${selectedCategoryObj?._id}&skip=${skipDownload}&limit=${limitDownload}`);
    } else {
      alert("Please Select Category");
    }
  };

  const handleProductExcelRedirect = (type) => {
    if (selectedCategoryObj && selectedLastCategoryObj) {
      window.open(`${url}/product/downloadExcelForProducts?categoryId=${selectedLastCategoryObj?._id}&lastCategoryId=${selectedCategoryObj?._id}&skip=${skipDownload}&limit=${limitDownload}`);
    } else {
      alert("Please Select Category");
    }
  };

  const handleCategorySelection = (event) => {
    setSelectedCategoryObj(event);
  };


  function filter(el) {
    let chk = false;
    let tempColumns = [...product_sale_columns.filter((el) => el?.searchable)];
    // console.log(tempColumns, "@@S");
    if (!tempColumns?.length) chk = true;
    tempColumns.forEach((elx) => {
      let val = elx?.selector(el);
      if (val) {
        if (typeof val == "string" || typeof val == "number") {
          if (`${val}`.toLowerCase().includes(searchStr.toLowerCase())) chk = true;
        }
      }
    });
    return chk;
  }

  const debouncedSave = useCallback(
    // skipValue, limitValue, filterCondition, searchQueryValue, school, company
    debounce(
      (nextValue) =>
        getProductsData(
          1,
          limit,
          nextValue,
        )(),
      1000
    ),

    [page, limit] // will be created only once initially
  );

  const handleChange = (value) => {
    setSearchStr(value);
    setPage(1)
    debouncedSave(value);
    // setLimit(10)
  };


  const handlePerRowsChange = (newPerPage, page) => {

    getProductsData(
      page,
      newPerPage,
      searchStr
    );
    setLimit(newPerPage);
  };
  const handlePageChange = (page) => {
    console.log(page, "handlePageChange");
    if (totalElements / page > 1) {
      setPage(page);
      getProductsData(
        page,
        limit,
        searchStr
      );
    }
  };


  return (
    <main>
      <section className="product-category">
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-12">
              <div className="d-flex align-items-center justify-content-between mb-4">
                <h5 className="blue-1">Product List</h5>
              </div>
              <div className="row">

                <div className="col-lg-9">
                  <CustomButton iconName="fa-solid fa-download" btnName="Product Pdf" isBtn btntype="button" ClickEvent={() => setProductPdfModal(true)} extraClass={"me-2"} />
                  {/* <CustomButton iconName="fa-solid fa-download" btnName="In Stock Pdf" isBtn btntype="button" ClickEvent={() => handleProductPdfRedirect("inStock")} extraClass={"me-2"} /> */}
                  <CustomButton isLink iconName="fa-solid fa-plus" btnName="ADD NEW PRODUCT" path="/Product/AddProduct" />
                </div>
                <div className="col-lg-3">

                  <SearchBox handleChange={handleChange} extraClass="bg-white" />
                </div>
              </div>
              <DashboardTable>
                <DataTable columns={product_sale_columns} data={productsArr}
                  pagination
                  paginationServer
                  paginationTotalRows={totalElements}
                  onChangeRowsPerPage={handlePerRowsChange}
                  onChangePage={handlePageChange} />
              </DashboardTable>
              <Modal open={productPdfModal} onClose={() => setProductPdfModal(false)} center showCloseIcon={false} styles={{ modal: { width: "60vw", height: "35vh", borderRadius: 5 } }}>
                <h2 className="text-center mt-2 mb-4">Download Product Pdf</h2>
                <Select options={categoryArr} placeholder="Category" onChange={(event) => handleCategorySelection(event)} />
                {selectedCategoryObj && selectedCategoryObj?._id && selectedCategoryObj?.subCategoryArr?.length > 0 && (
                  <Select options={selectedCategoryObj?.subCategoryArr} placeholder="Sub Category" onChange={(event) => setSelectedLastCategoryObj(event)} className="mt-4" />
                )}
                <div className="row">
                  <div className="col-6">
                    <label>From</label>
                    <input className="form-control" placeholder="From" value={skipDownload} onChange={(e) => setSkipDownload(e.target.value)} />

                  </div>
                  <div className="col-6">

                    <label>To</label>

                    <input className="form-control" placeholder="To" value={limitDownload} onChange={(e) => setLimitDownload(e.target.value)} />
                  </div>
                </div>

                <div className="d-flex justify-content-center ">
                  <CustomButton iconName="fa-solid fa-download" btnName="Product Pdf" isBtn btntype="button" ClickEvent={() => handleProductPdfRedirect("all")} extraClass={"me-2 mt-4"} />
                  <CustomButton iconName="fa-solid fa-download" btnName="Product Excel" isBtn btntype="button" ClickEvent={() => handleProductExcelRedirect()} extraClass={"me-2 mt-4"} />

                  <CustomButton iconName="fa-solid fa-download" btnName="In Stock Pdf" isBtn btntype="button" ClickEvent={() => handleProductPdfRedirect("inStock")} extraClass={"ms-4 mt-4"} />
                </div>
              </Modal>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default ProductList;
