import axios from "axios";
import { url } from "./url.service";

const serverUrl = url + "/Order";

export const getAllOrders = () => {
  return axios.get(serverUrl + "/getAllActiveOrders");
};

export const getOrderById = async (id) => {
  return axios.get(`${serverUrl}/getOrderById/${id}`);
};

export const createCodOrderFromAdmin = (obj) => {
  return axios.post(`${serverUrl}/createCodOrderFromAdmin`, obj);
};

export const getInvoiceById = async (id) => {
  return axios.get(`${serverUrl}/getInvoce/${id}`);
};

export const updateOrderById = async (id, obj) => {
  return axios.patch(`${serverUrl}/updateOrderById/${id}`, obj);
};

export const getPickupAddresses = async () => {
  return axios.get(`${serverUrl}/getPickupAddressFromShiprocket`);
};

export const createShipRocketShipment = async (id) => {
  return axios.get(`${serverUrl}/createShipRocketShipment/${id}`);
};

export const createShipRocketReturnShipment = async (id) => {
  return axios.patch(`${serverUrl}/createShipRocketReturnShipment/${id}`);
};

export const getAllCouriers = async (id) => {
  return axios.get(`${serverUrl}/getServiceableCouriers/${id}`);
};

export const generateAwbShipment = (obj) => {
  return axios.post(`${serverUrl}/assignCourierToOrder`, obj);
};
export const generateAwbReturnShipment = (id) => {
  return axios.patch(`${serverUrl}/createAWBForReturnOrder/${id}`);
};

export const generateLabel = (obj) => {
  return axios.post(`${serverUrl}/generateLabel`, obj);
};
export const cancelOrderById = (id) => {
  return axios.post(`${serverUrl}/cancelOrderById/${id}`);
};

export const recordOrderPayment = (obj) => {
  return axios.patch(`${serverUrl}/recordOrderPayment`, obj);
};
