import { combineReducers } from "redux";

import { authReducer } from "./auth/auth.reducer";
import { BrandReducer } from "./Brand/Brand.reducer";
import { TaxReducer } from "./Tax/Tax.reducer";
import { BannerReducer } from "./Banner/Branner.reducer";
import { userReducer } from "./Users/users.reducer";
import { AttributeReducer } from "./Attribute/Attribute.reducer";
import { CategoryReducer } from "./Category/Category.reducer";
import { ProductReducer } from "./Product/Product.reducer";
import { CouponReducer } from "./Coupons/Coupons.reducers";
import { GiftCardReducer } from "./GiftCard/GiftCard.reducers";
import { SupportReducer } from "./Support/Support.reducers";
import { FaqReducer } from "./Faq/Faq.reducers";
import { TermsReducer } from "./Terms/Terms.reducers";

const RootReducer = combineReducers({
  auth: authReducer,
  brand: BrandReducer,
  taxes: TaxReducer,
  banner: BannerReducer,
  users: userReducer,
  attribute: AttributeReducer,
  category: CategoryReducer,
  product: ProductReducer,
  coupon: CouponReducer,
  giftCard: GiftCardReducer,
  support: SupportReducer,
  Faq: FaqReducer,
  Terms: TermsReducer,
});

export default RootReducer;
