import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import { userAdd } from "../../redux/actions/Users/users.actions";
import CustomButton from "../Utility/Button";
import { DashboardBox } from "../Utility/DashboardBox";
import { AccessArr } from "../Utility/utils";
import { toastError, toastSuccess } from "../Utility/ToastUtils";
import { getById, updateUserWithAdmin } from "../../services/users.service";
function EditUsers() {
    const params = useParams()
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [selectedAccessArr, setSelectedAccessArr] = useState([]);
    const [password, setPassword] = useState("");
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [userObj, setUserObj] = useState(null);

    const getUser = async () => {
        try {
            let { data: res } = await getById(params.id);
            if (res) {
                setUserObj(res.data)
                if (res.data) {
                    setFirstName(res?.data?.firstName)
                    setLastName(res?.data?.lastName)
                    setPhone(res?.data?.phone)
                    setEmail(res?.data?.email)
                    setSelectedAccessArr(res.data.accessArr.map(el => ({ label: el, value: el })))

                }
            }
        } catch (error) {
            toastError(error);
        }
    }

    const handleSubmit = async () => {
        try {

            let obj = {
                firstName,
                lastName,
                phone,
                email,
                isActive: true,
                password,
                accessArr: selectedAccessArr.map((el) => el.value),
            };
            let { data: res } = await updateUserWithAdmin(userObj?._id, obj);
            if (res) {
                toastSuccess(res.message)
                navigate(-1)
            }
        } catch (error) {
            toastError(error)
        }
    };

    useEffect(() => {
        getUser()
    }, [])

    return (
        <main>
            <section className="product-category">
                <div className="container-fluid p-0">
                    <h5 className="blue-1 mb-4">Edit User</h5>
                    <DashboardBox>
                        <form className="form row">
                            <h5 className="blue-1 mb-4">Basic Info</h5>
                            <div className="col-12 col-md-4 mb-3">
                                <label>
                                    First Name <span className="red">*</span>
                                </label>
                                <input name="first_name" className="form-control" type="text" onChange={(e) => setFirstName(e.target.value)} value={firstName} required="" />
                            </div>
                            <div className="col-12 col-md-4 mb-3">
                                <label>
                                    Last Name
                                    <span className="red">*</span>
                                </label>
                                <input name="last_name" className="form-control" type="text" onChange={(e) => setLastName(e.target.value)} value={lastName} required="" />
                            </div>

                            <div className="col-12 col-md-4 mb-3">
                                <label>
                                    Mobile Number
                                    <span className="red">*</span>
                                </label>
                                <input name="last_name" className="form-control" type="number" maxLength={10} onChange={(e) => setPhone(`${e.target.value}`.length < 11 ? e.target.value : `${e.target.value}`.slice(0, 10))} value={phone} required="" />
                            </div>
                            <div className="col-12 col-md-4 mb-3">
                                <label>
                                    Email Address
                                    <span className="red">*</span>
                                </label>
                                <input name="text" onChange={(e) => setEmail(e.target.value)} value={email} className="form-control" type="email" />
                            </div>


                            <div className="col-12  col-md-4 mb-3">
                                <label>Access</label>
                                <Select onChange={(e) => setSelectedAccessArr(e)} isMulti value={selectedAccessArr} options={AccessArr} />
                            </div>
                            <div className="col-12 col-md-4 mb-3">
                                <label>
                                    Password
                                    <span className="red">*</span>
                                </label>
                                <input name="text" onChange={(e) => setPassword(e.target.value)} value={password} className="form-control" type="password" />
                            </div>

                            <div className="col-12 mt-2 text-center">
                                <CustomButton
                                    isBtn
                                    ClickEvent={(e) => {
                                        e.preventDefault();
                                        handleSubmit();
                                    }}
                                    iconName="fa-solid fa-check"
                                    btnName="Submit"
                                />
                            </div>
                        </form>
                    </DashboardBox>
                </div>
            </section>
        </main>
    );
}

export default EditUsers;
