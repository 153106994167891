import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import Select from "react-select";
import { addNotifications, getNotifications } from "../../services/Notifications.service";
import { images } from "../Images/Images";
import ActionIcon from "../Utility/ActionIcon";
import CustomButton from "../Utility/Button";
import { DashboardBox, DashboardTable } from "../Utility/DashboardBox";
import FileUpload from "../Utility/FileUpload";
import SearchBox from "../Utility/SearchBox";
import { toastError, toastSuccess } from "../Utility/ToastUtils";
import { usersGet } from "../../redux/actions/Users/users.actions";
import { useSelector, useDispatch } from "react-redux";

function ManageNotifications() {
    const dispatch = useDispatch();

    const [title, setTitle] = useState('');
    const [message, setMessage] = useState('');

    const [selectedUserArr, setSelectedUserArr] = useState([]);
    const [notificationArr, setNotificationArr] = useState([]);
    const users = useSelector((state) => state.users.users);
    const [displayUsersArr, setDisplayUsersArr] = useState([]);
    const handleAddNotification = async () => {
        try {
            let obj = {
                title,
                message,
                userArr: selectedUserArr.map(el => el._id)
            }
            let { data: res } = await addNotifications(obj)
            if (res) {
                toastSuccess(res.message)
                setTitle("")
                setMessage("")
                getAllNotifications()
            }
        } catch (error) {
            toastError(error)
        }
    }


    const blog_category_columns = [
        {
            name: "SL",
            selector: (row, index) => index + 1,
            sortable: true,
        },
        {
            name: "Title",
            cell: (row) => row?.title,
        },
        {
            name: "Description",
            selector: (row) => row.message,
        },

    ];

    const blog_category_data = [];


    const getAllNotifications = async () => {
        try {
            const { data: res } = await getNotifications();
            if (res) {
                setNotificationArr(res.data)
            }
        } catch (error) {
            toastError(error)
        }
    }

    const handleUserSelection = (element) => {
        setSelectedUserArr(element)

    };

    useEffect(() => {
        getAllNotifications()
        dispatch(usersGet(`role=USER`));
    }, [])
    useEffect(() => {
        if (users && users.length > 0) {
            setDisplayUsersArr([...users.map((el) => ({ ...el, label: `${el.firstName} ${el.lastName} | ${el.phone}`, value: el._id }))]);
        }
    }, [users]);



    return (
        <main>
            <section className="product-category">
                <div className="container-fluid p-0">
                    <div className="row">
                        <div className="col-12 col-md-4">
                            <h5 className="blue-1 mb-4">Add Notification</h5>
                            <DashboardBox>
                                <form action="#" className="form row">
                                    <div className="col-12 mb-3">
                                        <label className="blue-1 fs-12">
                                            Title<span className="red">*</span>
                                        </label>
                                        <input type="text" value={title} onChange={(e) => setTitle(e.target.value)} className="form-control" />
                                    </div>

                                    <div className="col-12 mb-3">
                                        <label className="blue-1 fs-12">
                                            Description<span className="red">*</span>
                                        </label>
                                        <input type="text" className="form-control" value={message} onChange={(e) => setMessage(e.target.value)} />
                                    </div>
                                    <div className="col-12 mb-3">
                                        <label>
                                            User's LIST<span className="red">*</span>
                                        </label>
                                        <Select options={displayUsersArr} value={selectedUserArr} onChange={(e) => handleUserSelection(e)} isMulti={true} />
                                    </div>
                                    <div className="col-12">
                                        <CustomButton isBtn btntype="button" ClickEvent={() => handleAddNotification()} iconName="fa-solid fa-check" btnName="ADD" />
                                    </div>
                                </form>
                            </DashboardBox>
                        </div>
                        <div className="col-12 col-md-8">
                            <div className="d-flex justify-content-between mb-2 align-items-center">
                                <h5 className="blue-1 m-0">Notification List</h5>
                                <SearchBox extraClass="bg-white" />
                            </div>
                            <DashboardTable>
                                <DataTable columns={blog_category_columns} data={notificationArr} pagination />
                            </DashboardTable>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
}

export default ManageNotifications;
