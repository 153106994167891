import React, { useState } from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { CATEGORYDelete, SetCATEGORYObj } from "../../../redux/actions/Category/Category.actions";
import ActionIcon from "../../Utility/ActionIcon";
import CustomButton from "../../Utility/Button";
import { DashboardBox, DashboardTable } from "../../Utility/DashboardBox";
import SearchBox from "../../Utility/SearchBox";
import AddCategory from "./AddCategory";

function Category() {
  // ==============================================================================================================
  const dispatch = useDispatch();
  const [ModalType, setModalType] = useState("");
  const [ModalName, setModalName] = useState("");
  const [ModalBox, setModalBox] = useState(false);
  const categoryArr = useSelector((state) => state.category.categories);
  const [searchStr, setSearchStr] = useState("");
  const handleCategoryEdit = (row) => {
    dispatch(SetCATEGORYObj(row));
  };

  const handleCategoryDelete = (id) => {
    if (window.confirm("Do you really want to delete this category ?. \n NOTE : ALL THE PRODUCTS IN THIS CATEGORY WILL ALSO BE DELETED")) {
      dispatch(CATEGORYDelete(id));
    } else {
    }
  };

  const category_columns = [
    {
      name: "ID",
      selector: (row, index) => index + 1,
      sortable: true,
      searchable: true,
    },
    {
      name: "Name",
      selector: (row) => row.name,
      searchable: true,
    },
    {
      name: "Parent Category",
      selector: (row) => (row.parentCategoryName ? row.parentCategoryName : "NA"),
    },
    {
      name: "Status",
      minWidth: "210px",
      maxWidth: "211px",
      button: true,
      cell: (row) => <CustomButton greenBtn noIcon btnName="Active" path={row.url} />,
    },
    {
      name: "Action",
      minWidth: "210px",
      maxWidth: "211px",
      cell: (row) => (
        <ActionIcon Uniquekey={row._id} remove edit deletePath="/Product/Category" onDeleteClick={() => handleCategoryDelete(row._id)} isRedirected={true} onEditClick={() => handleCategoryEdit(row)} editPath="/Product/Category" />
      ),
    },
  ];
  function filter(el) {
    let chk = false;
    let tempColumns = [...category_columns.filter((el) => el?.searchable)];
    // console.log(tempColumns, "@@S");
    if (!tempColumns?.length) chk = true;
    tempColumns.forEach((elx) => {
      let val = elx?.selector(el);
      if (val) {
        if (typeof val == "string" || typeof val == "number") {
          if (`${val}`.toLowerCase().includes(searchStr.toLowerCase())) chk = true;
        }
      }
    });
    return chk;
  }

  const handleChange = (value) => {
    setSearchStr(value);
  };

  // ==============================================================================================================

  return (
    <main>
      <section className="product-category">
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-12 col-md-5">
              <div className="d-flex align-items-center justify-content-between mb-3">
                <h5 className="blue-1 m-0">Add Category</h5>
                {/* <CustomButton isLink iconName="fa-solid fa-plus" btnName="BULK CATEGORY UPLOAD" path="/Product/Bulk-Category-Upload" roundedPill small /> */}
              </div>
              <DashboardBox>
                <AddCategory />
              </DashboardBox>
            </div>
            <div className="col-12 col-md-7">
              <div className="d-flex align-items-center justify-content-between mb-4">
                <h5 className="blue-1 m-0">Category List</h5>
                <div className="d-flex gap-3 align-items-center">
                  {/* <CustomButton isLink iconName="fa-solid fa-download" btnName="CATEGORY CSV" path="/Product/Bulk-Category-Upload" small roundedPill downloadAble /> */}
                  <SearchBox handleChange={handleChange} extraClass="bg-white" />
                </div>
              </div>
              <DashboardTable>
                <DataTable columns={category_columns} data={categoryArr && categoryArr.length > 0 ? categoryArr?.filter((el) => (searchStr ? filter(el) : true)) : []} pagination />
              </DashboardTable>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default Category;
