import { toastError, toastSuccess } from "../../../components/Utility/ToastUtils";
import * as Terms from "../../actions/Terms/Terms.actions";

const initialState = {
  terms: null,
  termObj: null,
  loading: false,
  error: null,
};

export const TermsReducer = (state = initialState, action) => {
  switch (action.type) {
    case Terms.TERM_ADD:
      return {
        ...state,
        loading: true,
      };
    case Terms.TERM_ADD_SUCCESS:
      //   console.log(act);
      toastSuccess(action.payload);

      return {
        ...state,
        loading: false,
      };
    case Terms.TERM_ADD_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case Terms.GET_ALL_TERMS:
      return {
        ...state,
        loading: true,
      };
    case Terms.GET_ALL_TERMS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        terms: action.payload.data,
      };
    case Terms.GET_ALL_TERMS_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case Terms.DELETE_TERM_BY_ID:
      return {
        ...state,
        loading: true,
      };
    case Terms.DELETE_TERM_BY_ID_SUCCESS:
      toastSuccess(action.payload.message);
      return {
        ...state,
        loading: false,
        error: null,
      };
    case Terms.DELETE_TERM_BY_ID_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case Terms.SET_TERM_OBJ:
      return {
        ...state,
        loading: true,
      };
    case Terms.SET_TERM_OBJ_SUCCESS:
      toastSuccess(action.payload.message);
      return {
        ...state,
        termObj: action.payload.data,
        loading: false,
        error: null,
      };
    case Terms.SET_TERM_OBJ_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case Terms.UPDATE_TERM_BY_ID:
      return {
        ...state,
        loading: true,
      };
    case Terms.UPDATE_TERM_BY_ID_SUCCESS:
      toastSuccess(action.payload.message);
      return {
        ...state,
        loading: false,
        error: null,
      };
    case Terms.UPDATE_TERM_BY_ID_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
