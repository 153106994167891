import { url } from "../../services/url.service";

export const generateFilePath = (fileName) => {
  return `${url}/uploads/${fileName}`;
};

export const CouponTypes = {
  percentage_off: "Percentage Off",
  flat_off: "Flat Off",
};

export const RolesObj = {
  ADMIN: "ADMIN",
  USER: "USER",
  SUBADMIN: "SUBADMIN",
};

export const RolesArr = [
  {
    label: "USER",
    value: "USER",
  },
  {
    label: "SUBADMIN",
    value: "SUBADMIN",
  },
];

export const AccessArr = [
  {
    label: "PRODUCTS",
    value: "PRODUCTS",
  },
  {
    label: "ORDERS",
    value: "ORDERS",
  },
  {
    label: "USER",
    value: "USER",
  },
  {
    label: "SUPPORT",
    value: "SUPPORT",
  },
  {
    label: "FRONTEND CMS",
    value: "FRONTENT CMS",
  },
  {
    label: "COUPONS/GIFTCARD",
    value: "COUPONS/GIFTCARD",
  },
  {
    label: "SETUP",
    value: "SETUP",
  },
  {
    label: "SYSTEM SETTINGS",
    value: "SYSTEM SETTINGS",
  },
];
