import * as CouponService from "../../../services/Coupon.service";
export const ADD_COUPONS = "ADD_COUPONS";
export const ADD_COUPONS_SUCCESS = "ADD_COUPONS_SUCCESS";
export const ADD_COUPONS_FAIL = "ADD_COUPONS_FAIL";

export const GET_ALL_COUPONS = "GET_ALL_COUPONS";
export const GET_ALL_COUPONS_SUCCESS = "GET_ALL_COUPONS_SUCCESS";
export const GET_ALL_COUPONS_FAIL = "GET_ALL_COUPONS_FAIL";

export const GET_ALL_ACTIVE_COUPONS = "GET_ALL_ACTIVE_COUPONS";
export const GET_ALL_ACTIVE_COUPONS_SUCCESS = "GET_ALL_ACTIVE_COUPONS_SUCCESS";
export const GET_ALL_ACTIVE_COUPONS_FAIL = "GET_ALL_ACTIVE_COUPONS_FAIL";

export const UPDATE_COUPON_BY_ID = "UPDATE_COUPON_BY_ID";
export const UPDATE_COUPON_BY_ID_SUCCESS = "UPDATE_COUPON_BY_ID_SUCCESS";
export const UPDATE_COUPON_BY_ID_FAIL = "UPDATE_COUPON_BY_ID_FAIL";

export const DELETE_COUPON_BY_ID = "DELETE_COUPON_BY_ID";
export const DELETE_COUPON_BY_ID_SUCCESS = "DELETE_COUPON_BY_ID_SUCCESS";
export const DELETE_COUPON_BY_ID_FAIL = "DELETE_COUPON_BY_ID_FAIL";

export const SET_COUPON_OBJ = "SET_COUPON_OBJ";
export const SET_COUPON_OBJ_SUCCESS = "SET_COUPON_OBJ_SUCCESS";
export const SET_COUPON_OBJ_FAIL = "SET_COUPON_OBJ_FAIL";

export const couponAdd = (formData) => async (dispatch) => {
  try {
    dispatch({ type: ADD_COUPONS });
    let { data: response } = await CouponService.addCoupon(formData);
    if (response) {
      console.log(response);
      dispatch({
        type: ADD_COUPONS_SUCCESS,
        payload: response.message,
      });
    }
  } catch (err) {
    console.error(err);
    dispatch({ type: ADD_COUPONS_FAIL, payload: err });
  }
};

export const couponGet = (formData) => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_COUPONS });
    let { data: response } = await CouponService.getAllCoupons(formData);
    if (response) {
      console.log(response.data, "DATA");
      dispatch({
        type: GET_ALL_COUPONS_SUCCESS,
        payload: { data: response.data, message: response.message },
      });
    }
  } catch (err) {
    console.error(err);
    dispatch({ type: GET_ALL_COUPONS_FAIL, payload: err });
  }
};

export const setCouponObj = (formData) => async (dispatch) => {
  try {
    dispatch({ type: SET_COUPON_OBJ });
    console.log(formData, "ACTIon");
    if (formData && formData?.name) {
      dispatch({
        type: SET_COUPON_OBJ_SUCCESS,
        payload: formData,
      });
    } else {
      dispatch({
        type: SET_COUPON_OBJ_SUCCESS,
        payload: { data: null },
      });
    }
  } catch (err) {
    console.error(err);
    dispatch({ type: SET_COUPON_OBJ_FAIL, payload: err });
  }
};

export const couponUpdateById = (id, formData) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_COUPON_BY_ID });
    let { data: response } = await CouponService.updateCouponById(id, formData);
    if (response) {
      dispatch({
        type: UPDATE_COUPON_BY_ID_SUCCESS,
        payload: response.message,
      });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: UPDATE_COUPON_BY_ID_FAIL, payload: error });
  }
};

export const couponDeleteById = (id) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_COUPON_BY_ID });
    let { data: response } = await CouponService.deleteCouponById(id);
    if (response) {
      dispatch({
        type: DELETE_COUPON_BY_ID_SUCCESS,
        payload: response.message,
      });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: DELETE_COUPON_BY_ID_FAIL, payload: error });
  }
};
