import React, { useEffect } from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { closeTicketById, getSupportTickets } from "../../redux/actions/Support/Support.actions";
import CustomButton from "../Utility/Button";
import { SupportTickets } from "../Utility/constants";
import { DashboardBox, DashboardTable } from "../Utility/DashboardBox";
function SupportTicket() {
  const dispatch = useDispatch();
  const supportTickets = useSelector((state) => state.support.supportTickets);

  const getSupport = () => {
    dispatch(getSupportTickets());
  };

  useEffect(() => {
    getSupport();
  }, []);

  const handleSupportTicketClose = (id) => {
    if (window.confirm("Do you really want to close this ticket?")) {
      dispatch(closeTicketById(id));
    }
  };
  const handleSearch = (e) => {
    console.log(e);
    dispatch(getSupportTickets(`status=${e.value}`));
  };

  const options = [
    { value: SupportTickets.Open, label: SupportTickets.Open },
    { value: SupportTickets.Close, label: SupportTickets.Close },
  ];
  const brand_columns = [
    {
      name: "SL",
      cell: (row, index) => index + 1,
      width: "5%",
      sortable: false,
    },
    {
      name: "Title",
      width: "30%",

      selector: (row) => row?.title,
    },
    {
      name: "USER NAME",
      selector: (row) => row?.userName,
      width: "20%",
    },

    {
      name: "STATUS",
      width: "10%",
      cell: (row) => <>{row?.status == SupportTickets.Open ? <CustomButton path="/Support-Ticket" greenBtn btnName={SupportTickets.Open} /> : <CustomButton path="/Support-Ticket" redBtn btnName={SupportTickets.Close} />}</>,
    },

    {
      name: "Action",
      width: "20%",

      cell: (row, index) => (
        <>
          {row?.status == SupportTickets.Open && <CustomButton btnName="Close" ClickEvent={() => handleSupportTicketClose(row?._id)} isBtn extraClass="m-3" iconName="fa-solid fa-ticket" />}
          <CustomButton path={`/Chatbox?ticketId=${row?._id}`} isLink iconName="fa-regular fa-comment" btnName="Chat" extraClass="m-3" />
        </>
      ),
    },
  ];

  return (
    <main>
      <section className="product-category" style={{ minHeight: "75vh" }}>
        <div className="container-fluid p-0">
          <div className="row gy-5">
            <div className="col-12">
              <h5 className="blue-1 mb-4">Support Ticket</h5>
              <DashboardBox>
                <form action="#" className="form row">
                  <div className="col-12 col-lg-4 mb-3">
                    <label>
                      STATUS
                      <span className="red">*</span>
                    </label>
                    <Select onChange={(e) => handleSearch(e)} options={options} />
                  </div>
                  {/* <div className="col-12 mb-2">
                    <CustomButton isBtn iconName="ion-ios-search" btnName="Search" />
                  </div> */}
                </form>
              </DashboardBox>
            </div>
            <div className="col-12">
              <div className="d-flex align-items-center justify-content-between mb-3">
                <h5 className="blue-1 m-0">Ticket List</h5>
                {/* <SearchBox extraClass="bg-white" /> */}
              </div>

              <DashboardTable>
                <DataTable columns={brand_columns} data={supportTickets && supportTickets.length > 0 ? supportTickets : []} pagination />
              </DashboardTable>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default SupportTicket;
