import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { CATEGORYAdd, CATEGORYGet, CATEGORYUpdate } from "../../../redux/actions/Category/Category.actions";
import CustomButton from "../../Utility/Button";
import { generalModelStatuses } from "../../Utility/constants";
import FileUpload from "../../Utility/FileUpload";

function AddCategory({ makeChange }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [slug, setSlug] = useState("");
  const [isSearchable, setIsSearchable] = useState(false);
  const [price, setPrice] = useState("");
  const [status, setStatus] = useState(generalModelStatuses.APPROVED);
  const [selectedParentCategoryId, setSelectedParentCategoryId] = useState("");
  const [toggleSubCategory, setToggleSubCategory] = useState(false);
  const [subcategoryArr, setSubcategoryArr] = useState([]);

  const [shortDescription, setShortDescription] = useState("");
  const [imageStr, setImageStr] = useState("");
  const [addSubCategory, setaddSubCategory] = useState(false);
  const [dimension_width, setDimension_width] = useState(0);
  const [dimension_height, setDimension_height] = useState(0);
  const [dimension_length, setDimension_length] = useState(0);
  const [dimension_weight, setDimension_weight] = useState(0);
  const [selectedDiscountType, setSelectedDiscountType] = useState("");
  const [despcription, setdespcription] = useState("");
  const [discountValue, setDiscountValue] = useState(0);

  const [prevCategoryObj, setPrevCategoryObj] = useState(null);
  const [prevCategoryId, setPrevCategoryId] = useState(null);
  const categoryArr = useSelector((state) => state.category.categories);
  const categoryObj = useSelector((state) => state.category.categoryObj);
  const [imageBannerStr, setImageBannerStr] = useState(null);

  const [imageArr, setImageArr] = useState([{ image: "" }]);

  const discount = [
    { value: "AMOUNT_OFF", label: "Amount OFF" },
    { value: "PERCENTAGE_OFF", label: "Percentage OFF" },
  ];

  const handleAddCategory = () => {
    let obj = {
      name,
      slug,
      price,
      status,
      isSearchable,
      weight: dimension_weight,
      height: dimension_height,
      width: dimension_width,
      length: dimension_length,
      description: despcription,
      shortDescription,
      discountValue,
      discountType: selectedDiscountType,
      imageStr,
      imageUploadArr: imageArr.filter((el) => el.image != ""),
      parentCategoryId: addSubCategory ? selectedParentCategoryId : "",
      imageBannerStr,
    };
    console.log(obj);
    console.log(obj);
    if (categoryObj?._id) {
      dispatch(CATEGORYUpdate(prevCategoryId, obj));
    } else {
      dispatch(CATEGORYAdd(obj));
    }
    navigate("/Product/Category");
  };

  const handleDiscountTypeChange = (obj) => {
    setSelectedDiscountType(obj.value);
  };
  useEffect(() => {
    if (categoryObj) {
      setName(categoryObj?.name);
      setSlug(categoryObj?.slug);
      setIsSearchable(categoryObj?.isSearchable);
      setStatus(categoryObj?.status);
      setPrice(categoryObj?.price);
      setDimension_height(categoryObj?.height);
      setDimension_length(categoryObj?.length);
      setDimension_weight(categoryObj?.weight);
      setDimension_width(categoryObj?.width);
      setDiscountValue(categoryObj?.discountValue);
      setSelectedDiscountType(categoryObj?.discountType);
      setdespcription(categoryObj?.description);
      setShortDescription(categoryObj?.shortDescription);
      if (categoryObj?.parentCategoryId) {
        setSelectedParentCategoryId(categoryObj?.parentCategoryId);
        setPrevCategoryObj({ label: categoryArr?.find((el) => el._id == categoryObj?.parentCategoryId)?.name, value: categoryObj.parentCategoryId });
        setaddSubCategory(true);
      }
      setPrevCategoryId(categoryObj?._id);
    }

    // return () => {
    //   dispatch(SetCATEGORYObj(null));
    // };
  }, [categoryObj]);

  useEffect(() => {
    dispatch(CATEGORYGet());
  }, []);

  const handleChange = (value) => {
    console.log(value);
    setSelectedParentCategoryId(value._id);
  };
  const handleFileSet = (value) => {
    setImageStr(value);
  };

  const handleFileBannerSet = (value) => {
    setImageBannerStr(value);
  };

  const handleFileBannerArrSet = (value, index) => {
    // setImageBannerStr(value);

    let tempArr = [...imageArr];
    tempArr[index].image = value;
    setImageArr([...tempArr]);
  };

  const handleImageRemove = (index) => {
    let tempArr = [...imageArr];
    tempArr = tempArr.filter((el, indexX) => indexX != index);
    setImageArr([...tempArr]);
  };

  const handleImageArrPush = () => {
    let tempArr = [...imageArr];
    tempArr.push({ image: "" });
    setImageArr([...tempArr]);
  };

  return (
    <div className={makeChange ? "makeChange" : ""}>
      <form className="form row">
        <div className={makeChange ? "col-12 col-md-6" : "col-12"}>
          <label className="blue-1 fs-12">
            Name <span className="red">*</span>
          </label>
          <input value={name} onChange={(event) => setName(event.target.value)} type="text" className="form-control" />
        </div>
        <div className={makeChange ? "col-12 col-md-6" : "col-12"}>
          <label className="blue-1 fs-12">
            SLUG <span className="red">*</span>
          </label>
          <input value={slug} onChange={(event) => setSlug(event.target.value)} type="text" className="form-control" />
        </div>
        <div className={makeChange ? "col-12 col-md-6" : "col-12"}>
          <label className="blue-1 fs-12">
            Price <span className="red">*</span>
          </label>
          <input value={price} onChange={(event) => setPrice(event.target.value)} type="number" className="form-control" />
        </div>
        <div className="col-12 col-md-3 mb-3">
          <label>Weight [Gm]</label>
          <input value={dimension_weight} onChange={(event) => setDimension_weight(event.target.value)} type="number" className="form-control" />
        </div>
        <div className="col-12 col-md-3 mb-3">
          <label>Length [Cm]</label>
          <input value={dimension_length} onChange={(event) => setDimension_length(event.target.value)} type="number" className="form-control" />
        </div>
        <div className="col-12 col-md-3 mb-3">
          <label>Breadth [Cm]</label>
          <input value={dimension_width} onChange={(event) => setDimension_width(event.target.value)} type="number" className="form-control" />
        </div>
        <div className="col-12 col-md-3 mb-3">
          <label>Height [Cm]</label>
          <input type="number" value={dimension_height} onChange={(event) => setDimension_height(event.target.value)} className="form-control" />
        </div>

        <div className={makeChange ? "col-12 col-md-6" : "col-12"}>
          <label>DISCOUNT</label>
          <input type="number" value={discountValue} onChange={(event) => setDiscountValue(event.target.value)} className="form-control" />
        </div>

        <div className={makeChange ? "col-12 col-md-6" : "col-12"}>
          <label>DISCOUNT TYPE</label>
          <Select options={discount} onChange={handleDiscountTypeChange} />
        </div>
        <div className={makeChange ? "col-12 col-md-6" : "col-12"}>
          <h5 className="blue-1 mb-4">Description</h5>
          <div className="col-12 mb-3">
            <textarea className="form-control" rows="5" onChange={(e) => setdespcription(e.target.value)} value={despcription} />
          </div>
        </div>
        <div className={makeChange ? "col-12 col-md-6" : "col-12"}>
          <h5 className="blue-1 mb-4">Short Description</h5>
          <div className="col-12 mb-3">
            <textarea className="form-control" rows={1} onChange={(e) => setShortDescription(e.target.value)} value={shortDescription} />
          </div>
        </div>

        {/* <div className={makeChange ? "col-12" : "col-12"}>
          <label className="blue-1 fs-12">
            ICON (TO USE THEMEFY ICON PLEASE TYPE HERE OR SELECT FONTAWESOME
            FROM LIST)
          </label>
          <input type="text" className="form-control" />
        </div> */}
        <div className={makeChange ? "col-12 col-md-4" : "col-12"}>
          <label className="blue-1 fs-12">SEARCHABLE</label>
          <div className="d-flex">
            <div className="form-check form-check-inline d-flex align-items-center">
              <input className="form-check-input" type="radio" name="category-status" id="category-searchable-Radio1" checked={isSearchable} onClick={() => setIsSearchable(true)} />
              <label className="form-check-label fs-14" htmlFor="category-searchable-Radio1">
                Active
              </label>
            </div>
            <div className="form-check form-check-inline d-flex align-items-center">
              <input className="form-check-input" type="radio" name="category-status" id="category-searchable-Radio2" checked={!isSearchable} onClick={() => setIsSearchable(false)} />
              <label className="form-check-label fs-14" htmlFor="category-searchable-Radio2">
                Inactive
              </label>
            </div>
          </div>
        </div>
        <div className={makeChange ? "col-12 col-md-4" : "col-12"}>
          <label className="blue-1 fs-12">Status</label>
          <div className="d-flex">
            <div className="form-check form-check-inline d-flex align-items-center">
              <input className="form-check-input" checked={status == generalModelStatuses.APPROVED} onClick={() => setStatus(generalModelStatuses.APPROVED)} type="radio" />
              <label className="form-check-label fs-14" htmlFor="category-Radio1">
                Active
              </label>
            </div>
            <div className="form-check form-check-inline d-flex align-items-center">
              <input className="form-check-input" type="radio" checked={status == generalModelStatuses.DECLINED} onClick={() => setStatus(generalModelStatuses.DECLINED)} />
              <label className="form-check-label fs-14" htmlFor="category-Radio2">
                Inactive
              </label>
            </div>
          </div>
        </div>
        <div className={makeChange ? "col-12 col-md-4 d-flex align-items-end" : "col-12"}>
          <div className="form-check form-check-inline d-flex align-items-center pointer">
            <input
              className="form-check-input"
              type="checkbox"
              name="category-status"
              value="option1"
              id="add-as-sub-category"
              checked={addSubCategory}
              onChange={(e) => {
                if (e.target.checked) {
                  setaddSubCategory(true);
                } else {
                  setaddSubCategory(false);
                }
              }}
            />
            <label className="form-check-label fs-14 pointer" htmlFor="add-as-sub-category">
              Add as Sub Category
            </label>
          </div>
        </div>
        {addSubCategory && (
          <div className="col-12">
            <label className="blue-1 fs-12">
              PARENT CATEGORY<span className="red">*</span>
            </label>
            {categoryArr && categoryArr.length > 0 && (
              <Select defaultValue={prevCategoryObj} onChange={handleChange} options={categoryArr.filter((el) => el.level == 1).map((el) => ({ ...el, label: el.name, value: el._id }))} placeholder="Select from options" />
            )}
          </div>
        )}
        <div className="col-12">
          <label className="blue-1 fs-12">UPLOAD PHOTO</label>
          <FileUpload onFileChange={handleFileSet} />
          <div className="form-text fs-12">(Ratio: (225 X 225)PX)</div>
        </div>

        <div className="col-12">
          <label className="blue-1 fs-12">UPLOAD Banner PHOTO</label>
          <FileUpload onFileChange={handleFileBannerSet} />
          <div className="form-text fs-12">(Ratio: (1920 X 1080)PX)</div>
        </div>

        {imageArr &&
          imageArr.length > 0 &&
          imageArr.map((el, index) => {
            return (
              <div className="d-flex row ">
                <div className="col-9">
                  <label className="blue-1 fs-12">UPLOAD Slider PHOTO {index + 1}</label>
                  <FileUpload onFileChange={handleFileBannerArrSet} index={index} />
                  <div className="form-text fs-12">(Ratio: (1920 X 1080)PX)</div>
                </div>
                <div className="col-2">
                  <CustomButton extraClass={"mt-4 "} btntype="button" ClickEvent={() => handleImageRemove(index)} iconName="fa-solid fa-xmark" btnName="Remove" isBtn />
                </div>
              </div>
            );
          })}

        <div className="col-12">
          <CustomButton btntype="button" extraClass={"me-2"} ClickEvent={handleImageArrPush} btnName="Add More Images" isBtn small={makeChange ? true : false} />
        </div>
        <div className="col-12">
          <CustomButton btntype="button" extraClass={"mt-4"} ClickEvent={handleAddCategory} iconName="fa-solid fa-check" btnName="Save" isBtn small={makeChange ? true : false} />
        </div>
      </form>
    </div>
  );
}

export default AddCategory;
