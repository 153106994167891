import React, { useEffect } from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { giftCardGet, setGiftCardObj } from "../../redux/actions/GiftCard/GiftCard.actions";
import { deleteGiftCardById } from "../../services/GiftCard.service";
import ActionIcon from "../Utility/ActionIcon";
import CustomButton from "../Utility/Button";
import { DashboardTable } from "../Utility/DashboardBox";

function ViewGiftCards() {
  const dispatch = useDispatch();

  const giftCardArr = useSelector((state) => state.giftCard.giftCards);

  useEffect(() => {
    dispatch(giftCardGet());
  }, []);

  const handleEdit = (row) => {
    dispatch(setGiftCardObj(row));
  };

  const handleDelete = (id) => {
    if (window.confirm("Do you really want to delete this Gift Card?")) {
      dispatch(deleteGiftCardById(id));
    }
  };
  const brand_columns = [
    {
      name: "ID",
      selector: (row, index) => index + 1,
      sortable: true,
      width: "5%",
    },
    {
      name: "Name",
      selector: (row) => row.name,
      width: "20%",
    },
    {
      name: "Code",
      selector: (row) => row.code,
      width: "20%",
    },

    {
      name: "Redeemed Amount",
      selector: (row) => `₹ ${row.redeemedAmount}`,
      width: "20%",
    },
    {
      name: "IsUsed",
      button: true,
      width: "20%",
      cell: (row) => (row?.isUsed ? "Yes" : "No"),
    },
    {
      name: "Action",
      width: "20%",
      cell: (row) => <ActionIcon isRedirected={true} onEditClick={() => handleEdit(row)} editPath="/AddGiftCard" onDeleteClick={() => handleDelete(row?._id)} deletePath="/ViewGiftCards" remove edit Uniquekey={row._id} />,
    },
  ];

  return (
    <main>
      <section className="product-category">
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-12">
              <div className="d-flex align-items-center justify-content-between mb-3">
                <h5 className="blue-1 m-0">Gift Cards</h5>
                <div className="d-flex align-items-center gap-3">
                  <CustomButton isLink iconName="fa-solid fa-plus" btnName="ADD NEW GIFT CARD" path="/AddGiftCard" small roundedPill />
                  {/* <SearchBox extraClass="bg-white" /> */}
                </div>
              </div>
              <DashboardTable>
                <DataTable columns={brand_columns} data={giftCardArr && giftCardArr.length > 0 ? giftCardArr : []} pagination />
              </DashboardTable>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default ViewGiftCards;
