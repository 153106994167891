import { toastError, toastSuccess } from "../../../components/Utility/ToastUtils";
import * as GiftCard from "../../actions/GiftCard/GiftCard.actions";

const initialState = {
  giftCards: null,
  giftCardObj: null,
  loading: false,
  error: null,
};

export const GiftCardReducer = (state = initialState, action) => {
  switch (action.type) {
    case GiftCard.ADD_GIFT_CARD:
      return {
        ...state,
        loading: true,
      };
    case GiftCard.ADD_GIFT_CARD_SUCCESS:
      toastSuccess(action.payload);

      return {
        ...state,
        loading: false,
      };
    case GiftCard.ADD_GIFT_CARD_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case GiftCard.GET_ALL_GIFT_CARD:
      return {
        ...state,
        loading: true,
      };
    case GiftCard.GET_ALL_GIFT_CARD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        giftCards: action.payload.data,
      };
    case GiftCard.GET_ALL_GIFT_CARD_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case GiftCard.DELETE_GIFT_CARD_BY_ID:
      return {
        ...state,
        loading: true,
      };
    case GiftCard.DELETE_GIFT_CARD_BY_ID_SUCCESS:
      toastSuccess(action.payload.message);
      return {
        ...state,
        loading: false,
        error: null,
      };
    case GiftCard.DELETE_GIFT_CARD_BY_ID_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case GiftCard.UPDATE_GIFT_CARD_BY_ID:
      return {
        ...state,
        loading: true,
      };
    case GiftCard.UPDATE_GIFT_CARD_BY_ID_SUCCESS:
      toastSuccess(action.payload);
      return {
        ...state,
        loading: false,
        error: null,
      };
    case GiftCard.UPDATE_GIFT_CARD_BY_ID_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case GiftCard.SET_GIFT_CARD_OBJ:
      return {
        ...state,
        loading: true,
      };
    case GiftCard.SET_GIFT_CARD_OBJ_SUCCESS:
      toastSuccess(action.payload.message);
      return {
        ...state,
        giftCardObj: action.payload,
        loading: false,
        error: null,
      };
    case GiftCard.SET_GIFT_CARD_OBJ_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
