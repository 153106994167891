import axios from "axios";
import { url } from "./url.service";

const serverUrl = url + "/SupportTickets";

export const getAllSupportTicket = (query) => {
  return axios.get(`${serverUrl}/getAll?${query}`);
};

export const getMessagesByTicketId = (id) => {
  return axios.get(`${serverUrl}/getMessagesByTicketId/${id}`);
};

export const sendMessageInSupport = (obj) => {
  return axios.post(`${serverUrl}/sendMessageInSupport`, obj);
};

export const updateStatusById = (id, formData) => {
  return axios.patch(`${serverUrl}/updateStatusById/${id}`, formData);
};

export const closeSupportTicketByTicketId = async (id) => {
  return axios.patch(`${serverUrl}/closeSupportTicketByTicketId/${id}`);
};
