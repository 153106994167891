import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { getContactRequest } from "../../services/contact.service";
import { DashboardTable } from "../Utility/DashboardBox";
function ContactMail() {
  // ======================================================================================
  const [contactArr, setContactArr] = useState([]);
  const mail_columns = [
    {
      name: "SL",
      selector: (row, i) => i + 1,
      sortable: true,
      width: "10%",
    },
    {
      name: "FirstName",
      selector: (row) => row.firstName,
      width: "20%",
    },
    {
      name: "LastName",
      selector: (row) => row.lastName,
      width: "20%",
    },

    {
      name: "PHONE",
      selector: (row) => row.phone,
      width: "20%",
    },
    {
      name: "EMAIL",
      selector: (row) => row.email,
      width: "20%",
    },
    {
      name: "MESSAGE",
      cell: (row) => row.message,
      width: "35%",
    },
  ];

  const mail_data = [];
  // ======================================================================================

  const getData = async () => {
    try {
      const { data: res } = await getContactRequest();
      if (res) {
        setContactArr(res.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <main>
      <section className="product-category" style={{ minHeight: "75vh" }}>
        <div className="container-fluid p-0">
          <h5 className="blue-1 mb-4">Contact Request</h5>
          <DashboardTable>
            <DataTable columns={mail_columns} data={contactArr} pagination />
          </DashboardTable>
        </div>
      </section>
    </main>
  );
}

export default ContactMail;
