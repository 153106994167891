import axios from "axios";
import { url } from "./url.service";

const serverUrl = url + "/Notifications";

export const addNotifications = (formData) => {
    return axios.post(serverUrl + "/addNotifications", formData);
};

export const getNotifications = () => {
    return axios.get(`${serverUrl}/getNotifications`);
};
