import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { giftCardAdd, giftCardUpdateById } from "../../redux/actions/GiftCard/GiftCard.actions";
import CustomButton from "../Utility/Button";
import { DashboardBox } from "../Utility/DashboardBox";
function AddGiftCards() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [validity, setValidity] = useState("");

  const [minimumOrderAmount, setMinimumOrderAmount] = useState(0);
  const [redeemedAmount, setRedeemedAmount] = useState(0);

  const [isUpdateGiftCard, setIsUpdateGiftCard] = useState(false);
  const [selectedCouponId, setselectedGiftCardId] = useState("");
  const giftCardObj = useSelector((state) => state.giftCard.giftCardObj);

  const [minimumValidDate, setMinimumValidDate] = useState(moment().format("YYYY-MM-DD"));
  useEffect(() => {
    if (giftCardObj) {
      setselectedGiftCardId(giftCardObj._id);
      setName(giftCardObj.name);
      setValidity(giftCardObj.validity);
      setRedeemedAmount(giftCardObj.redeemedAmount);

      setIsUpdateGiftCard(true);
    }
  }, [giftCardObj]);

  const handleSubmit = () => {
    let obj = {
      name,
      validity,
      redeemedAmount,
    };
    if (isUpdateGiftCard) {
      dispatch(giftCardUpdateById(selectedCouponId, obj));
    } else {
      dispatch(giftCardAdd(obj));
    }
    navigate(-1)
  };
  return (
    <main>
      <section className="product-category">
        <div className="container-fluid p-0">
          <h5 className="blue-1 mb-4">{isUpdateGiftCard ? "Update" : "Add New "} Gift Card</h5>
          <form action="#" className="form">
            <div className="row">
              <div className="col-12 col-md-8 mb-0">
                <DashboardBox>
                  <div className="row">
                    {/* <h5 className="blue-1 mb-4">Coupon Information</h5> */}
                    <div className="col-12">
                      <label>
                        Name <span className="red">*</span>
                      </label>
                      <input value={name} onChange={(event) => setName(event.target.value)} type="text" className="form-control" />
                    </div>

                    <div className="col-12">
                      <label>
                        Validity <span className="red">*</span>
                      </label>
                      <input value={validity} min={minimumValidDate} onChange={(event) => setValidity(event.target.value)} type="date" className="form-control" />
                    </div>

                    <div className="col-12">
                      <label>Redeem Amount</label>
                      <input value={redeemedAmount} onChange={(event) => setRedeemedAmount(event.target.value)} type="number" className="form-control" />
                    </div>
                  </div>
                  <div className="col-12 mt-2">
                    <CustomButton btntype="button" ClickEvent={handleSubmit} isBtn iconName="fa-solid fa-check" btnName="Save" />
                  </div>
                </DashboardBox>
              </div>
            </div>
          </form>
        </div>
      </section>
    </main>
  );
}

export default AddGiftCards;
