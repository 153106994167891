import React, { useRef, useEffect, useState } from "react";
import Select from "react-select";
import DataTable from "react-data-table-component";
import ActionIcon from "../../Utility/ActionIcon";
import CustomButton from "../../Utility/Button";
import { DashboardBox, DashboardTable } from "../../Utility/DashboardBox";
import { useSelector, useDispatch } from "react-redux";
import { PRODUCTGet } from "../../../redux/actions/Product/Product.actions";
import { getCompanySettings } from "../../../services/CompanySettings.service";
import { toastError, toastSuccess } from "../../Utility/ToastUtils";
import { usersGet } from "../../../redux/actions/Users/users.actions";
import { createCodOrderFromAdmin } from "../../../services/order.service";
import { couponGet, setCouponObj } from "../../../redux/actions/Coupons/Coupons.actions";
import { CouponTypes } from "../../Utility/utils";
import { useNavigate } from "react-router-dom";

function AddProduct() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const productArr = useSelector((state) => state.product.products);
  const users = useSelector((state) => state.users.users);
  const [displayUsersArr, setDisplayUsersArr] = useState([]);
  const [orderProductArr, setOrderProductArr] = useState([]);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [countryName, setCountryName] = useState("");
  const [stateName, setStateName] = useState("");
  const [cityName, setCityName] = useState("");
  const [pincode, setPincode] = useState("");
  const [address, setAddress] = useState("");
  const [selectedUserObj, setSelectedUserObj] = useState({});
  const [displayProductArr, setDisplayProductArr] = useState([]);
  const [gst, setGst] = useState(18);
  const [selectedCouponObj, setSelectedCouponObj] = useState({});
  const [couponDiscount, setCouponDiscount] = useState(0);
  const [paymentRemarks, setPaymentRemarks] = useState("");
  const [shippingCharges, setShippingCharges] = useState(0);
  const couponArr = useSelector((state) => state.coupon.coupons);
  const [addressArr, setAddressArr] = useState([]);
  const [paidAmount, setPaidAmount] = useState(0);
  useEffect(() => {
    dispatch(couponGet());
  }, []);
  useEffect(() => {
    dispatch(usersGet(`role=USER`));

    getCompany();
    dispatch(PRODUCTGet());
  }, []);

  useEffect(() => {
    if (productArr && productArr.length > 0) {
      setDisplayProductArr([
        ...productArr.filter(el => el.stock > 0).map((el) => ({
          ...el,
          label: el.name,
          value: el?._id,
          gst,
          qty: 0,
          sellingPrice: el?.categoryObj?.price - (el?.categoryObj?.discountType == "AMOUNT_OFF" ? el?.categoryObj?.discountValue : el?.categoryObj?.price * (el?.categoryObj?.discountValue / 100)),
        })),
      ]);
    }
  }, [productArr]);

  const getCompany = async () => {
    try {
      let { data: res } = await getCompanySettings();
      if (res) {
        setGst(res.data.orderGst);
      }
    } catch (error) {
      toastError(error);
    }
  };

  const method = [{ value: "cod", label: "Cash On Delivery" }];



  const Order_package_columns = [
    {
      name: "Product Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "sku",
      selector: (row) => row.sku,
    },
    {
      name: "Stock",
      selector: (row) => row.stock,
    },

    {
      name: "Selling Price",
      cell: (row, index) => <input type="number" min={0} value={row.sellingPrice} onChange={(e) => handleOrderChange(e.target.value, index, "mrp", "true")} className="form-control" />,
    },

    {
      name: "qty",
      cell: (row, index) => <input type="number" min={0} max={10} value={row.qty} onChange={(e) => handleOrderChange(e.target.value, index, "qty", "false")} className="form-control" />,
    },
    {
      name: "gst",
      cell: (row, index) => <input type="number" min={0} max={2} value={row.gst} onChange={(e) => handleOrderChange(e.target.value, index, "gst", "false")} className="form-control" />,
    },
    {
      name: "Total Price",
      cell: (row, index) => <>₹{row.totalPrice}</>,
    },
    {
      name: "Remove",
      button: true,
      cell: (row, index) => <ActionIcon isRedirected onDeleteClick={() => handleOrderProductDelete(index)} deletePath="/Order/Total-Order" remove Uniquekey={row.id} />,
    },
  ];

  const handleOrderChange = (value, index, element, totalCheck) => {
    let tempOrderProductArr = [...orderProductArr];
    tempOrderProductArr[index][element] = value;
    setOrderProductArr([...tempOrderProductArr]);
    if (totalCheck == "false") {
      handleTotalPriceCalculationInArr(tempOrderProductArr, index);
    }
  };

  const handleTotalPriceCalculationInArr = (arr, index) => {
    let tempTotalPrice = arr[index]?.sellingPrice * arr[index].qty + arr[index]?.sellingPrice * arr[index].qty * (arr[index].gst / 100);
    arr[index].totalPrice = tempTotalPrice;
    // arr[index].mrp = arr[index]?.buyingPrice + arr[index].buyingPrice * (arr[index].gst / 100) + arr[index].buyingPrice * 0.25;
    setOrderProductArr([...arr]);
    calculateCouponDiscount([...arr]);
  };
  const handleOrderProductDelete = (index) => {
    let tempArr = [...orderProductArr];
    tempArr.splice(index, 1);
    setOrderProductArr([...tempArr]);
  };
  useEffect(() => {
    if (users && users.length > 0) {
      setDisplayUsersArr([...users.map((el) => ({ ...el, label: `${el.firstName} ${el.lastName} | ${el.phone}`, value: el._id }))]);
    }
  }, [users]);



  const handleProductSelection = (e) => {
    console.log(e);
    setOrderProductArr(e);
  };
  const handleUserSelection = (element) => {
    console.log(element, "CHECK");

    setSelectedUserObj(element);
    setName(`${element.firstName} ${element.lastName}`);
    setPhone(element.phone);
    setEmail(element.email);
    setAddressArr(element?.addressArr?.map((el) => ({ ...el, label: el.address, value: el._id })));
  };

  const handleCouponSelection = (e) => {
    console.log(e);
    if (orderProductArr?.reduce((acc, el) => acc + el.totalPrice, 0) >= e.minimumOrderAmount) {
      setSelectedCouponObj(e);
    } else {
      alert("Coupon not allowed");
    }
  };

  const calculateCouponDiscount = (arr) => {
    let tempArr = [...arr];
    let tempAmount = tempArr?.reduce((acc, el) => acc + el.totalPrice, 0);
    console.log(selectedCouponObj, "AD");
    if (selectedCouponObj?.couponType == CouponTypes.flat_off) {
      console.log(selectedCouponObj?.discountValue, "DIS");

      setCouponDiscount(selectedCouponObj?.discountValue);
    } else if (selectedCouponObj?.couponType == CouponTypes.percentage_off) {
      let tempDiscount = tempAmount * (selectedCouponObj?.discountValue / 100);
      console.log(tempDiscount, "DIS");
      setCouponDiscount(tempDiscount);
    }
  };

  const handleOrderPlace = async () => {
    try {
      let obj = {
        userId: selectedUserObj?._id,
        addressObj: {
          firstName: name,
          phone,
          email,
          address,
          pincode,
          state: stateName,
          city: cityName,
          country: countryName,
        },
        couponId: selectedCouponObj?._id,
        paidAmount,
        paymentRemarks,
        shippingCharges,
        productArr: orderProductArr.map((el) => ({ productId: el._id, quantity: el.qty, categoryId: el?.categoryObj?._id })),
      };
      const { data: res } = await createCodOrderFromAdmin(obj);
      if (res) {
        toastSuccess(res.message);
        navigate("/Order/Total-Order")

      }
    } catch (error) {
      console.error(error);
      toastError(error);
    }
  };

  const handleAddressSelection = (e) => {
    setAddress(e.address);
    setPincode(e.pincode);
    setStateName(e.state);
    setCityName(e.city);
    setCountryName("India");
  };

  return (
    <main>
      <section className="mt-4">
        <div className="container-fluid p-0">
          <h5 className="blue-1 mb-4">Create New Order</h5>
          <DashboardBox>
            <form action="#" className="form">
              <div className="row">
                <div className="col-12 col-md-6">
                  <DashboardBox>
                    <div className="row">
                      <h5 className="blue-1 mb-3">Product Information</h5>
                      <div className="col-12 mb-3">
                        <label>
                          PRODUCT LIST<span className="red">*</span>
                        </label>
                        <Select options={displayProductArr} isMulti onChange={(e) => handleProductSelection(e)} />
                      </div>
                      {/* <div className="col-12 mb-3">
                        <label>
                          PAYMENT METHOD <span className="red">*</span>
                        </label>
                        <Select options={method} />
                      </div> */}
                      {/* <div className="col-12 mb-3">
                        <Alert variant="filled" severity="warning" icon={false} style={{ background: "#ffeeba", color: "#856404" }} onClose={() => {}}>
                          Please Save Customer Address Before Add Product
                        </Alert>
                      </div> */}
                    </div>
                  </DashboardBox>
                </div>
                <div className="col-12 col-md-6">
                  <DashboardBox className="row">
                    <div className="col-12">
                      <h5 className="blue-1 mb-3">Address Info</h5>
                    </div>
                    <div className="col-12 mb-3">
                      <label>
                        User's LIST<span className="red">*</span>
                      </label>
                      <Select options={displayUsersArr} onChange={(e) => handleUserSelection(e)} />
                    </div>
                    {addressArr && addressArr.length > 0 && (
                      <div className="col-12 mb-3">
                        <label>
                          Address List<span className="red">*</span>
                        </label>
                        <Select options={addressArr} onChange={(e) => handleAddressSelection(e)} />
                      </div>
                    )}
                    <div className="col-12 col-md-6 mb-3">
                      <label>
                        Name <span className="red">*</span>
                      </label>
                      <input type="text" value={name} onChange={(e) => setName(e.target.value)} className="form-control" />
                    </div>
                    <div className="col-12 col-md-6 mb-3">
                      <label>
                        EMAIL ADDRESS <span className="red">*</span>
                      </label>
                      <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} className="form-control" />
                    </div>
                    <div className="col-12 col-md-6 mb-3">
                      <label>
                        PHONE NUMBER
                        <span className="red">*</span>
                      </label>
                      <input type="number" value={phone} onChange={(e) => setPhone(e.target.value)} className="form-control" />
                    </div>
                    <div className="col-12 col-md-6 mb-3">
                      <label>
                        ADDRESS<span className="red">*</span>
                      </label>
                      <input type="text" value={address} onChange={(e) => setAddress(e.target.value)} className="form-control" />
                    </div>
                    <div className="col-12 col-md-6 mb-3">
                      <label>
                        COUNTRY <span className="red">*</span>
                      </label>
                      <input type="text" value={countryName} onChange={(e) => setCountryName(e.target.value)} className="form-control" />
                    </div>
                    <div className="col-12 col-md-6 mb-3">
                      <label>
                        STATE <span className="red">*</span>
                      </label>
                      <input type="text" value={stateName} onChange={(e) => setStateName(e.target.value)} className="form-control" />
                    </div>
                    <div className="col-12 col-md-6 mb-3">
                      <label>
                        CITY <span className="red">*</span>
                      </label>
                      <input type="text" value={cityName} onChange={(e) => setCityName(e.target.value)} className="form-control" />
                    </div>
                    <div className="col-12 col-md-6 mb-3">
                      <label>
                        POSTAL CODE<span className="red">*</span>
                      </label>
                      <input type="text" className="form-control" value={pincode} onChange={(e) => setPincode(e.target.value)} />
                    </div>
                    <div className="col-12 mb-3">
                      <label>
                        Coupon<span className="red">*</span>
                      </label>
                      <Select options={couponArr && couponArr.length > 0 ? [...couponArr.map((el) => ({ ...el, label: el.name, value: el._id }))] : []} value={selectedCouponObj} onChange={(e) => handleCouponSelection(e)} />
                    </div>
                    <div className="col-12 col-md-6 mb-3">
                      <label>
                        Paid Amount<span className="red">*</span>
                      </label>
                      <input type="number" className="form-control" value={paidAmount} onChange={(e) => setPaidAmount(e.target.value)} />
                    </div>
                    <div className="col-12 col-md-6 mb-3">
                      <label>
                        Paid Remarks<span className="red">*</span>
                      </label>
                      <input type="text" className="form-control" value={paymentRemarks} onChange={(e) => setPaymentRemarks(e.target.value)} />
                    </div>
                    <div className="col-12 col-md-12 mb-3">
                      <label>
                        Shipping Charges<span className="red">*</span>
                      </label>
                      <input type="text" className="form-control" value={shippingCharges} onChange={(e) => setShippingCharges(e.target.value)} />
                    </div>
                    {/* <div className="col-12 mb-3">
                      <div className="form-check form-check-inline pointer">
                        <input
                          className="rounded-circle form-check-input"
                          type="checkbox"
                          name="category-status"
                          value="option1"
                          id="same-address"
                          onChange={(e) => {
                            handleCheckbox(e.target.checked);
                          }}
                        />
                        <label className="form-check-label fs-14 pointer" htmlFor="same-address">
                          Billing Address is not as same as Shipping Address
                        </label>
                      </div>
                    </div> */}
                    {/* <div className="row shipping-address d-none col-12 mb-0" ref={shippingAddress}>
                      <div className="col-12">
                        <h5 className="blue-1 mt-2 mb-1">Shipping Address</h5>
                      </div>
                      <div className="col-12 col-md-6 mb-3">
                        <label>
                          Name <span className="red">*</span>
                        </label>
                        <input type="text" className="form-control" />
                      </div>
                      <div className="col-12 col-md-6 mb-3">
                        <label>
                          EMAIL ADDRESS <span className="red">*</span>
                        </label>
                        <input type="email" className="form-control" />
                      </div>
                      <div className="col-12 col-md-6 mb-3">
                        <label>
                          PHONE NUMBER <span className="red">*</span>
                        </label>
                        <input type="tel" pattern="/^[0-9]+$/" minLength="10" maxLength="12" className="form-control" />
                      </div>
                      <div className="col-12 col-md-6 mb-3">
                        <label>
                          ADDRESS<span className="red">*</span>
                        </label>
                        <input type="text" className="form-control" />
                      </div>
                      <div className="col-12 col-md-6 mb-3">
                        <label>
                          COUNTRY <span className="red">*</span>
                        </label>
                        <Select options={country} placeholder="Select from options" />
                      </div>
                      <div className="col-12 col-md-6 mb-3">
                        <label>
                          STATE <span className="red">*</span>
                        </label>
                        <Select options={state} placeholder="Select from options" />
                      </div>
                      <div className="col-12 col-md-6 mb-3">
                        <label>
                          CITY <span className="red">*</span>
                        </label>
                        <Select options={city} placeholder="Select from options" />
                      </div>
                      <div className="col-12 col-md-6 mb-3">
                        <label>
                          POSTAL CODE<span className="red">*</span>
                        </label>
                        <input type="text" className="form-control" />
                      </div>
                      <div className="col-12 mb-3">
                        <div className="form-check form-check-inline pointer">
                          <input
                            className="rounded-circle form-check-input"
                            type="checkbox"
                            name="category-status"
                            value="option1"
                            id="same-address"
                            onChange={(e) => {
                              handleCheckbox(e.target.checked);
                            }}
                          />
                          <label className="form-check-label fs-14 pointer" htmlFor="same-address">
                            Billing Address is not as same as Shipping Address
                          </label>
                        </div>
                      </div>
                    </div> */}
                    <div className="col-12 mt-2">
                      <CustomButton isBtn iconName="fa-solid fa-check" btntype="button" ClickEvent={() => handleOrderPlace()} btnName="Save" />
                    </div>
                  </DashboardBox>
                </div>
                <div className="col-12 col-md-8">
                  <h5 className="blue-1 mb-4">Order </h5>
                  <DashboardTable className="border p-0 border-dark">
                    <DataTable columns={Order_package_columns} data={orderProductArr && orderProductArr.length > 0 ? orderProductArr : []} />
                  </DashboardTable>
                </div>
                <div className="col-12 col-md-4">
                  <h5 className="blue-1 mb-4">Summary</h5>
                  <DashboardBox>
                    <ul className="fs-14 bg-dark bg-opacity-10">
                      <li className="d-flex justify-content-between border-bottom border-dark border-opacity-25 pb-2 mb-2">
                        <span className="blue-1 fw-600">Total Quantity:</span>
                        {orderProductArr?.reduce((acc, el) => acc + Number(el.qty), 0)}
                      </li>
                      <li className="d-flex justify-content-between border-bottom border-dark border-opacity-25 pb-2 mb-2">
                        <span className="blue-1 fw-600">Sub Total:</span>₹ {orderProductArr?.reduce((acc, el) => acc + el.totalPrice, 0)}
                      </li>
                      <li className="d-flex justify-content-between border-bottom border-dark border-opacity-25 pb-2 mb-2">
                        <span className="blue-1 fw-600">Shipping Charges:</span>₹ {shippingCharges}
                      </li>
                      <li className="d-flex justify-content-between border-bottom border-dark border-opacity-25 pb-2 mb-2">
                        <span className="blue-1 fw-600">Coupon Discount:</span>₹ {couponDiscount}
                      </li>
                      <li className="d-flex justify-content-between border-bottom border-dark border-opacity-25 pb-2 mb-2">
                        <span className="blue-1 fw-600">Paid Amount:</span>₹ {couponDiscount}
                      </li>

                      <li className="d-flex justify-content-between border-bottom border-dark border-opacity-25 pb-2 mb-2">
                        <span className="blue-1 fw-600">Grand total:</span>₹ {(orderProductArr?.reduce((acc, el) => acc + el.totalPrice, 0) - couponDiscount - paidAmount) + parseInt(shippingCharges)}
                      </li>
                    </ul>
                  </DashboardBox>
                </div>
              </div>
            </form>
          </DashboardBox>
        </div>
      </section>
    </main>
  );
}

export default AddProduct;
