import axios from "axios";
import { url } from "./url.service";

const serverUrl = url + "/Coupon";

export const addCoupon = (formData) => {
  return axios.post(serverUrl + "/createCoupon", formData);
};

export const getAllCoupons = () => {
  return axios.get(`${serverUrl}/getAllCoupons`);
};

export const deleteCouponById = (id) => {
  return axios.patch(`${serverUrl}/deleteCouponById/${id}`);
};

export const updateCouponById = (id, formData) => {
  return axios.patch(`${serverUrl}/updateCouponById/${id}`, formData);
};
