import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { userAdd } from "../../redux/actions/Users/users.actions";
import CustomButton from "../Utility/Button";
import { DashboardBox } from "../Utility/DashboardBox";
import FileUpload from "../Utility/FileUpload";
import { AccessArr, RolesArr, RolesObj } from "../Utility/utils";
function AddCustomer() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [shopName, setShopName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [alternateNumber, setAlternateNumber] = useState("");
  const [text, settext] = useState("");
  const [dob, setDob] = useState(new Date());
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [pincode, setPincode] = useState("");
  const [language, setLanguage] = useState("");
  const [visitingCard, setVisitingCard] = useState("");
  const [shopImage, setShopImage] = useState("");
  const [onlinePortal, setOnlinePortal] = useState("");
  const [kycStatus, setKycStatus] = useState("Approve");
  const [seletedRoleObj, setSeletedRoleObj] = useState({ label: RolesObj.USER, value: RolesObj?.USER });
  const [selectedAccessArr, setSelectedAccessArr] = useState([]);
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleSubmit = () => {
    console.log(selectedAccessArr);
    let obj = {
      firstName,
      lastName,
      shopName,
      phone,
      alternateNumber: seletedRoleObj?.value == RolesObj?.SUBADMIN ? phone : alternateNumber,
      text,
      email,
      dob,
      country,
      state,
      city,
      pincode,
      language,
      visitingCard,
      shopImage,
      onlinePortal,
      kycStatus,
      isActive: true,
      role: seletedRoleObj?.value,
      password,
      accessArr: selectedAccessArr.map((el) => el.value),
    };
    navigate(-1)
    dispatch(userAdd(obj));
    console.log(obj);
  };

  return (
    <main>
      <section className="product-category">
        <div className="container-fluid p-0">
          <h5 className="blue-1 mb-4">Add New Customer</h5>
          <DashboardBox>
            <form className="form row">
              <h5 className="blue-1 mb-4">Basic Info</h5>
              <div className="col-12 col-md-4 mb-3">
                <label>
                  First Name <span className="red">*</span>
                </label>
                <input name="first_name" className="form-control" type="text" onChange={(e) => setFirstName(e.target.value)} value={firstName} required="" />
              </div>
              <div className="col-12 col-md-4 mb-3">
                <label>
                  Last Name
                  <span className="red">*</span>
                </label>
                <input name="last_name" className="form-control" type="text" onChange={(e) => setLastName(e.target.value)} value={lastName} required="" />
              </div>
              <div className="col-12  col-md-4 mb-3">
                <label>Role</label>
                <Select onChange={(e) => setSeletedRoleObj(e)} value={seletedRoleObj} options={RolesArr} />
              </div>
              <div className="col-12 col-md-4 mb-3">
                <label>
                  Mobile Number
                  <span className="red">*</span>
                </label>
                <input name="last_name" className="form-control" type="number" maxLength={10} onChange={(e) => setPhone(`${e.target.value}`.length < 11 ? e.target.value : `${e.target.value}`.slice(0, 10))} value={phone} required="" />
              </div>
              <div className="col-12 col-md-4 mb-3">
                <label>
                  Email Address
                  <span className="red">*</span>
                </label>
                <input name="text" onChange={(e) => setEmail(e.target.value)} value={email} className="form-control" type="email" />
              </div>
              {seletedRoleObj?.value == RolesObj?.USER && (
                <>
                  <div className="col-12 col-md-4 mb-3">
                    <label>
                      Shop Name
                      <span className="red">*</span>
                    </label>
                    <input name="last_name" className="form-control" type="text" onChange={(e) => setShopName(e.target.value)} value={shopName} required="" />
                  </div>
                  <div className="col-12 col-md-4 mb-3">
                    <label>
                      Alternate Number
                      <span className="red">*</span>
                    </label>
                    <input
                      name="last_name"
                      className="form-control"
                      type="number"
                      maxLength={10}
                      onChange={(e) => setAlternateNumber(`${e.target.value}`.length < 11 ? e.target.value : `${e.target.value}`.slice(0, 10))}
                      value={alternateNumber}
                      required=""
                    />
                  </div>

                  <div className="col-12 col-md-4 mb-3">
                    <label>
                      D.O.B.
                      <span className="red">*</span>
                    </label>
                    <input name="text" onChange={(e) => setDob(e.target.value)} value={dob} className="form-control" type="date" />
                  </div>
                  <div className="col-12 col-md-4 mb-3">
                    <label>
                      Country
                      <span className="red">*</span>
                    </label>
                    <input name="text" onChange={(e) => setCountry(e.target.value)} value={country} className="form-control" type="text" />
                  </div>
                  <div className="col-12 col-md-4 mb-3">
                    <label>
                      State
                      <span className="red">*</span>
                    </label>
                    <input name="text" onChange={(e) => setState(e.target.value)} value={state} className="form-control" type="text" />
                  </div>
                  <div className="col-12 col-md-4 mb-3">
                    <label>
                      City
                      <span className="red">*</span>
                    </label>
                    <input name="text" onChange={(e) => setCity(e.target.value)} value={city} className="form-control" type="text" />
                  </div>
                  <div className="col-12 col-md-4 mb-3">
                    <label>
                      Pin code
                      <span className="red">*</span>
                    </label>
                    <input name="text" onChange={(e) => setPincode(e.target.value)} value={pincode} className="form-control" type="text" />
                  </div>
                  <div className="col-12 col-md-4 mb-3">
                    <label>
                      Language
                      <span className="red">*</span>
                    </label>
                    <input name="text" onChange={(e) => setLanguage(e.target.value)} value={language} className="form-control" type="text" />
                  </div>
                  <div className="col-12 col-md-4 mb-3">
                    <label>
                      Visiting Card
                      <span className="red">*</span>
                    </label>
                    <FileUpload onFileChange={(e) => setVisitingCard(e)} />
                  </div>
                  <div className="col-12 col-md-4 mb-3">
                    <label>
                      Shop Image
                      <span className="red">*</span>
                    </label>
                    <FileUpload onFileChange={(e) => setShopImage(e)} />
                  </div>
                  <div className="col-12 col-md-4 mb-3">
                    <label>
                      Online Portal
                      <span className="red">*</span>
                    </label>
                    <FileUpload onFileChange={(e) => setOnlinePortal(e)} />
                  </div>
                </>
              )}
              {seletedRoleObj?.value == RolesObj?.SUBADMIN && (
                <>
                  <div className="col-12  col-md-4 mb-3">
                    <label>Access</label>
                    <Select onChange={(e) => setSelectedAccessArr(e)} isMulti value={selectedAccessArr} options={AccessArr} />
                  </div>
                  <div className="col-12 col-md-4 mb-3">
                    <label>
                      Password
                      <span className="red">*</span>
                    </label>
                    <input name="text" onChange={(e) => setPassword(e.target.value)} value={password} className="form-control" type="password" />
                  </div>
                </>
              )}

              <div className="col-12 mt-2 text-center">
                <CustomButton
                  isBtn
                  ClickEvent={(e) => {
                    e.preventDefault();
                    handleSubmit();
                  }}
                  iconName="fa-solid fa-check"
                  btnName="Create"
                />
              </div>
            </form>
          </DashboardBox>
        </div>
      </section>
    </main>
  );
}

export default AddCustomer;
