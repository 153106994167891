import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

export default function CustomerAddress() {
    const params = useParams()

    useEffect(() => {
        console.log(params.id)
    }, [])

    return (
        <div>CustomerAddress</div>
    )
}
