import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useDispatch } from "react-redux";
import { setCouponObj } from "../../redux/actions/Coupons/Coupons.actions";
import { deleteUser, getQueryUser } from "../../services/users.service";
import ActionIcon from "../Utility/ActionIcon";
import CustomButton from "../Utility/Button";
import { DashboardTable } from "../Utility/DashboardBox";
import SearchBox from "../Utility/SearchBox";
import { toastError } from "../Utility/ToastUtils";

function ViewUsers() {
  const dispatch = useDispatch();

  const [usersArr, setUsersArr] = useState([]);

  const [searchStr, setSearchStr] = useState("");
  const handleUserGet = async () => {
    try {
      const { data: res } = await getQueryUser("SUBADMIN");
      if (res) {
        setUsersArr(res.data);
      }
    } catch (error) {
      toastError(error);
    }
  };
  useEffect(() => {
    handleUserGet();
  }, []);

  const handleEdit = (row) => {
    dispatch(setCouponObj(row));
  };

  const handleDelete = async (id) => {
    try {
      if (window.confirm("Do you really want to delete this User?")) {
        let { data: res } = await deleteUser(id);
        if (res) {
          handleUserGet();
        }
      }
    } catch (error) {
      toastError(error);
    }
  };
  const brand_columns = [
    {
      name: "ID",
      selector: (row, index) => index + 1,
      sortable: true,
      width: "5%",
      searchable: true,
    },
    {
      name: "Name",
      selector: (row) => row.firstName + row?.lastName,
      width: "10%",
      searchable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      width: "10%",
      searchable: true,
    },
    {
      name: "Phone",
      selector: (row) => row.phone,
      width: "20%",
      searchable: true,
    },
    {
      name: "Access",
      cell: (row) => row?.accessArr?.reduce((acc, el) => acc + el + ",", ""),
      width: "20%",
      searchable: false,
    },

    // {
    //   name: "Status",
    //   button: true,
    //   width: "20%",
    //   cell: (row) => <CustomButton greenBtn btnName="Active" />,
    // },
    {
      name: "Action",
      width: "20%",
      searchable: false,
      cell: (row) => <ActionIcon isRedirected={true} edit editPath={`/Edit-User/${row?._id}`} onEditClick={(e) => console.log(e)} onDeleteClick={() => handleDelete(row?._id)} deletePath="/View-Users" remove Uniquekey={row._id} />,
    },
  ];

  function filter(el) {
    let chk = false;
    let tempColumns = [...brand_columns.filter((el) => el?.searchable)];
    // console.log(tempColumns, "@@S");
    if (!tempColumns?.length) chk = true;
    tempColumns.forEach((elx) => {
      let val = elx?.selector(el);
      if (val) {
        if (typeof val == "string" || typeof val == "number") {
          if (`${val}`.toLowerCase().includes(searchStr.toLowerCase())) chk = true;
        }
      }
    });
    return chk;
  }

  const handleChange = (value) => {
    setSearchStr(value);
  };

  return (
    <main>
      <section className="product-category">
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-12">
              <div className="d-flex align-items-center justify-content-between mb-3">
                <h5 className="blue-1 m-0">Users List</h5>
                <div className="d-flex align-items-center gap-3">
                  <CustomButton isLink iconName="fa-solid fa-plus" btnName="ADD NEW User" path="/Customer-Create" small roundedPill />
                  <SearchBox handleChange={handleChange} extraClass="bg-white" />
                </div>
              </div>
              <DashboardTable>
                <DataTable columns={brand_columns} data={usersArr?.filter((el) => (searchStr ? filter(el) : true))} pagination />
              </DashboardTable>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default ViewUsers;
