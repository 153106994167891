import { addFaq, deleteFaq, getFaq, updateFaq } from "../../../services/Faq.service";

export const FAQ_ADD = "FAQ_ADD";
export const FAQ_ADD_SUCCESS = "FAQ_ADD_SUCCESS";
export const FAQ_ADD_FAIL = "FAQ_ADD_FAIL";

export const GET_ALL_FAQS = "GET_ALL_FAQS";
export const GET_ALL_FAQS_SUCCESS = "GET_ALL_FAQS_SUCCESS";
export const GET_ALL_FAQS_FAIL = "GET_ALL_FAQS_FAIL";

export const UPDATE_FAQ_BY_ID = "UPDATE_FAQ_BY_ID";
export const UPDATE_FAQ_BY_ID_SUCCESS = "UPDATE_FAQ_BY_ID_SUCCESS";
export const UPDATE_FAQ_BY_ID_FAIL = "UPDATE_FAQ_BY_ID_FAIL";

export const SET_FAQ_OBJ = "SET_FAQ_OBJ";
export const SET_FAQ_OBJ_SUCCESS = "SET_FAQ_OBJ_SUCCESS";
export const SET_FAQ_OBJ_FAIL = "SET_FAQ_OBJ_FAIL";

export const DELETE_FAQ_BY_ID = "DELETE_FAQ_BY_ID";
export const DELETE_FAQ_BY_ID_SUCCESS = "DELETE_FAQ_BY_ID_SUCCESS";
export const DELETE_FAQ_BY_ID_FAIL = "DELETE_FAQ_BY_ID_FAIL";

export const FAQAdd = (formData) => async (dispatch) => {
  try {
    dispatch({ type: FAQ_ADD });
    let { data: response } = await addFaq(formData);
    if (response) {
      console.log(response);
      dispatch({
        type: FAQ_ADD_SUCCESS,
        payload: response.message,
      });
    }
  } catch (err) {
    console.error(err);
    dispatch({ type: FAQ_ADD_FAIL, payload: err });
  }
};

export const FAQGet = () => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_FAQS });
    let { data: response } = await getFaq();
    if (response) {
      console.log(response);
      dispatch({
        type: GET_ALL_FAQS_SUCCESS,
        payload: { data: response.data, message: response.message },
      });
    }
  } catch (err) {
    console.error(err);
    dispatch({ type: FAQ_ADD_FAIL, payload: err });
  }
};

export const SetFAQObj = (formData) => async (dispatch) => {
  try {
    console.log(formData);
    dispatch({ type: SET_FAQ_OBJ });
    if (formData) {
      dispatch({
        type: SET_FAQ_OBJ_SUCCESS,
        payload: { data: formData },
      });
    } else {
      dispatch({
        type: SET_FAQ_OBJ_SUCCESS,
        payload: { data: null },
      });
    }
  } catch (err) {
    console.error(err);
    dispatch({ type: SET_FAQ_OBJ_FAIL, payload: { message: "NOT FOUND" } });
  }
};

export const FAQUpdate = (formData, id) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_FAQ_BY_ID });
    let { data: response } = await updateFaq(formData, id);
    if (response) {
      console.log(response);
      dispatch({
        type: UPDATE_FAQ_BY_ID_SUCCESS,
        payload: { message: response.message },
      });
    }
  } catch (err) {
    console.error(err);
    dispatch({ type: UPDATE_FAQ_BY_ID_FAIL, payload: err });
  }
};

export const FAQDelete = (id) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_FAQ_BY_ID });
    let { data: response } = await deleteFaq(id);
    if (response) {
      console.log(response);
      dispatch({
        type: DELETE_FAQ_BY_ID_SUCCESS,
        payload: { message: response.message },
      });
      FAQGet();
    }
  } catch (err) {
    console.error(err);
    dispatch({ type: DELETE_FAQ_BY_ID_FAIL, payload: err });
  }
};
