import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useSearchParams } from "react-router-dom";
import { createShipRocketReturnShipment, createShipRocketShipment, generateAwbReturnShipment, getAllCouriers, getInvoiceById, getOrderById, getPickupAddresses, updateOrderById } from "../../../services/order.service";
import { url } from "../../../services/url.service";
import CustomButton from "../../Utility/Button";
import { OrderStatus } from "../../Utility/constants";
import { DashboardBox, DashboardTable } from "../../Utility/DashboardBox";
import { toastError, toastSuccess } from "../../Utility/ToastUtils";
import { generateFilePath } from "../../Utility/utils";

function ReturnSaleDetail(props) {
  // =========================================================================================

  const [searchParams, setSearchParams] = useSearchParams();

  const [orderObj, setOrderObj] = useState({});
  const [shipment_height, setShipment_height] = useState(0);
  const [shipment_weight, setShipment_weight] = useState(0);
  const [shipment_length, setShipment_length] = useState(0);
  const [shipment_width, setShipment_width] = useState(0);
  const [pickupAddressArr, setPickupAddressArr] = useState([]);
  const [selectedOrderStatus, setSelectedOrderStatus] = useState(null);
  const [selectedPickupAddress, setSelectedPickupAddress] = useState(null);
  const [courierArr, setCourierArr] = useState([]);
  const [paidAmount, setPaidAmount] = useState(0);
  const [paymentRemarks, setPaymentRemarks] = useState("");
  const [selectedCourierObj, setSelectedCourierObj] = useState(null);
  const getOrder = async () => {
    try {
      const { data: res } = await getOrderById(searchParams.get("orderId"));
      if (res) {
        console.log(res.data, "DATA");
        setOrderObj(res.data);
        if (res?.data?.shipment_height) {
          setShipment_height(res?.data?.shipment_height);
        }
        if (res?.data?.shipment_length) {
          setShipment_length(res?.data?.shipment_length);
        }
        if (res?.data?.shipment_weight) {
          setShipment_weight(res?.data?.shipment_weight);
        }
        if (res?.data?.shipment_width) {
          setShipment_width(res?.data?.shipment_width);
        }
        if (res?.data?.pickupAddressObj?.id) {
          setSelectedPickupAddress({ label: res?.data?.pickupAddressObj?.pickup_location, value: res?.data?.pickupAddressObj?.pickup_location });
        }
        getCourier(res.data._id, res?.data?.shipment_weight);
        setPaidAmount(res?.data?.paidAmount);
        setPaymentRemarks(res?.data?.paymentRemarks);
        setSelectedOrderStatus({ label: res.data?.status, value: res.data?.status });
      }
    } catch (error) {
      console.error(error);
    }
  };
  const getPickupAddress = async () => {
    try {
      const { data: res } = await getPickupAddresses();
      if (res) {
        setPickupAddressArr([...res?.data?.shipping_address.map((el) => ({ ...el, label: el.pickup_location, value: el.pickup_location }))]);
      }
    } catch (error) {
      toastError(error);
    }
  };
  const getCourier = async (id, Weight) => {
    try {
      console.log(shipment_weight);
      if (parseInt(`${Weight}`) > 0 || parseInt(`${shipment_weight}`) > 0) {
        const { data: res } = await getAllCouriers(id);
        console.log(res);
        if (res) {
          setCourierArr([...res?.data?.available_courier_companies?.map((el) => ({ ...el, label: el.courier_name, value: el.courier_name }))]);
        }
      }
    } catch (error) {
      toastError(error);
    }
  };

  useEffect(() => {
    getOrder();
    getPickupAddress();
  }, []);

  const sale_columns = [
    {
      name: "SL",
      selector: (row, index) => index + 1,
      sortable: true,
    },
    {
      name: "Image",
      cell: (row) => <img src={generateFilePath(row?.productId?.productImage)} alt={row.name} />,
    },
    {
      name: "Name",
      selector: (row) => row.name,
    },
    {
      name: "Details",
      cell: (row) => (
        <>
          <p className="fs-14">Qty: {row.quantity}</p>
        </>
      ),
    },
    {
      name: "Price",
      selector: (row) => `₹ ${row.price}`,
    },
    {
      name: "TAX/GST",
      selector: (row) => `₹ ${row.cgst + row.sgst}`,
    },

    {
      name: "Total",
      selector: (row) => parseInt((row.price + row?.cgst + row?.sgst) * row.quantity),
    },
  ];

  const handleOrderStatusChange = async () => {
    try {
      let obj = {
        status: selectedOrderStatus?.value,
        orderStatusArr: [...orderObj?.orderStatusArr, { status: selectedOrderStatus?.value, updatedAt: new Date() }],
      };
      const { data: res } = await updateOrderById(orderObj?._id, obj);
      if (res) {
        toastSuccess(res.message);
        getOrder();
      }
    } catch (error) {
      console.error(error);
      toastError(error);
    }
  };

  const handleShipmentDetailsSave = async () => {
    try {
      let obj = {
        shipment_height,
        shipment_length,
        shipment_weight,
        shipment_width,
      };
      if (selectedPickupAddress) {
        let tempObj = pickupAddressArr.find((el) => el.pickup_location == selectedPickupAddress?.value);
        obj.pickupAddressObj = tempObj;
      }
      const { data: res } = await updateOrderById(orderObj?._id, obj);
      if (res) {
        toastSuccess(res.message);
        getOrder();
      }
    } catch (error) {
      console.error(error);
      toastError(error);
    }
  };

  const handleShipmentCreation = async () => {
    try {
      const { data: res } = await createShipRocketReturnShipment(orderObj?._id);
      if (res) {
        console.log(res);
        toastSuccess(res.message);
      }
    } catch (error) {
      toastError(error);
    }
  };

  const handleGenerateAWB = async () => {
    try {
      const { data: res } = await generateAwbReturnShipment(orderObj?._id);
      if (res) {
        toastSuccess(res.message);
        getOrder();
      }
    } catch (error) {
      toastError(error);
    }
  };

  //   =========================================================================================

  return (
    <main>
      <section className="product-category">
        <div className="container-fluid p-0">
          <div className="col-12 col-md-8">
            <div className="d-flex justify-content-between align-items-center mb-4">
              <h5 className="blue-1 m-0">Order Details</h5>
              {/* <CustomButton ClickEvent={() => handlePrint()} path={`/Order/Sale-Detail?orderId=${orderObj?._id}`} isLink downloadAble btnName="PRINT" noIcon noIconMargin /> */}
              {/* {(orderObj?.status != "Delivered" || orderObj?.status != "CANCELLED") && <CustomButton ClickEvent={() => handleOrderCancel()} isBtn btntype="button" btnName="Cancel Order" noIcon noIconMargin />} */}
            </div>
          </div>
          <div className="row">
            <DashboardBox className="col-12 col-md-8 row gy-4 m-0">
              <div className="col-12 col-md-6">
                <DashboardBox className="blue-1">
                  <div className="customer-profile">
                    <h6 className="blue-1 text-capitalize mb-3">Pickup Address</h6>
                    <ul className="blue-1 fs-14">
                      <li>
                        <span className="fw-600">
                          Name<span>:</span>
                        </span>
                        {orderObj?.pickupAddressObj?.name}
                      </li>
                      <li>
                        <span className="fw-600">
                          Email<span>:</span>
                        </span>
                        {orderObj?.pickupAddressObj?.email}
                      </li>
                      <li>
                        <span className="fw-600">
                          Phone<span>:</span>
                        </span>
                        {orderObj?.pickupAddressObj?.phone}
                      </li>
                      <li>
                        <span className="fw-600">
                          Address<span>:</span>
                        </span>
                        {orderObj?.pickupAddressObj?.address}
                      </li>
                      <li>
                        <span className="fw-600">
                          City<span>:</span>
                        </span>
                        {orderObj?.pickupAddressObj?.city}
                      </li>
                      <li>
                        <span className="fw-600">
                          State<span>:</span>
                        </span>
                        {orderObj?.pickupAddressObj?.state}
                      </li>
                      <li>
                        <span className="fw-600">
                          Country<span>:</span>
                        </span>
                        {orderObj?.pickupAddressObj?.country}
                      </li>
                      <li>
                        <span className="fw-600">
                          Postcode<span>:</span>
                        </span>
                        {orderObj?.pickupAddressObj?.pin_code}
                      </li>
                    </ul>
                  </div>
                </DashboardBox>
              </div>
              <div className="col-12 col-md-6">
                <DashboardBox className="blue-1">
                  <div className="customer-profile">
                    <h6 className="blue-1 text-capitalize mb-3">Shipping Info</h6>
                    <ul className="blue-1 fs-14">
                      <li>
                        <span className="fw-600">
                          Name<span>:</span>
                        </span>
                        {orderObj?.addressObj?.firstName}
                        {orderObj?.addressObj?.lastName}
                      </li>
                      {/* <li>
                        <span className="fw-600">
                          Email<span>:</span>
                        </span>
                        {orderObj?.addressObj?.email}
                      </li> */}
                      <li>
                        <span className="fw-600">
                          Phone<span>:</span>
                        </span>
                        {orderObj?.addressObj?.phone}
                      </li>
                      <li>
                        <span className="fw-600">
                          Address<span>:</span>
                        </span>
                        {orderObj?.addressObj?.address}
                      </li>
                      <li>
                        <span className="fw-600">
                          City<span>:</span>
                        </span>
                        {orderObj?.addressObj?.city}
                      </li>
                      <li>
                        <span className="fw-600">
                          State<span>:</span>
                        </span>
                        {orderObj?.addressObj?.state}
                      </li>
                      {/* <li>
                        <span className="fw-600">
                          Landmark<span>:</span>
                        </span>
                        {orderObj?.addressObj?.landmark}
                      </li> */}
                      <li>
                        <span className="fw-600">
                          Postcode<span>:</span>
                        </span>
                        {orderObj?.addressObj?.pincode}
                      </li>
                    </ul>
                  </div>
                </DashboardBox>
              </div>

              <div className="col-12">
                <DashboardBox className="blue-1">
                  <div className="d-flex justify-content-between align-items-end">
                    <div>
                      <h5 className="blue-1 mb-3">Package: {orderObj?._id}</h5>
                      <CustomButton redBtn btnName={`${orderObj?.status}`} />
                    </div>
                    {/* {orderObj && (orderObj?.shippingObj?.shipment_id == "" || !orderObj?.shippingObj?.shipment_id) ? (
                      <CustomButton isBtn btntype="button" ClickEvent={() => handleShipmentCreation()} iconName="fa-solid fa-check" btnName="Create Shipment" />
                    ) : ( */}
                    <h6 className="border py-2 m-0 px-4 text-white bg-black rounded-2 fs-14">Shipping Id : {orderObj?.returnObj?.returnShipmentId ? orderObj?.returnObj?.returnShipmentId : "NA"}</h6>
                    {/* )} */}
                  </div>
                  <DashboardTable className="my-4">
                    <DataTable columns={sale_columns} data={orderObj?.items} pagination />
                  </DashboardTable>
                  <div className="customer-profile">
                    <h6 className="blue-1 text-capitalize mb-3">Order Info</h6>
                    <div className="row">
                      <div className="col-5">
                        <ul className="blue-1 fs-14">
                          <li>
                            <span className="fw-600">
                              Is Paid<span>:</span>
                            </span>
                            {orderObj?.paymentObj?.paymentChk == 1 ? "Paid" : orderObj?.paymentObj?.paymentChk == 2 ? "COD" : "No"}
                          </li>
                          <li>
                            <span className="fw-600">
                              Subtotal<span>:</span>
                            </span>
                            ₹ {orderObj?.subTotalAmount}
                          </li>

                          <li>
                            <span className="fw-600">
                              Shipping Charge<span>:</span>
                            </span>
                            ₹ 00.00
                          </li>
                          <li>
                            <span className="fw-600">
                              TAX/GST<span>:</span>
                            </span>
                            ₹ {orderObj?.items?.reduce((acc, el) => acc + el.cgst + el.sgst, 0)}
                          </li>
                          <li>
                            <span className="fw-600">
                              Paid Amount<span>:</span>
                            </span>
                            ₹ {orderObj?.paidAmount}
                          </li>
                          <li>
                            <span className="fw-600">
                              Grand Total<span>:</span>
                            </span>
                            ₹ {orderObj?.totalAmount}
                          </li>
                        </ul>
                      </div>

                      <div className="col-5"></div>
                    </div>
                  </div>
                </DashboardBox>
              </div>
            </DashboardBox>
            <div className="col-12 col-md-4">
              <DashboardBox className="mt-4">
                {!(orderObj?.returnObj && orderObj?.returnObj?.returnShipmentId) && <CustomButton isBtn btntype="button" ClickEvent={() => handleShipmentCreation()} iconName="fa-solid fa-check" btnName="Create Shipment" />}
                {orderObj && orderObj?.returnObj && orderObj?.returnObj?.returnShipmentId && <CustomButton isBtn btntype="button" ClickEvent={() => handleGenerateAWB()} iconName="fa-solid fa-check" btnName="Generate AWB" />}
              </DashboardBox>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default ReturnSaleDetail;
