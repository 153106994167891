import axios from "axios";
import { url } from "./url.service";

const serverUrl = url + "/Faq";

export const addFaq = (formData) => {
  return axios.post(serverUrl + "/add", formData);
};

export const getFaq = (query) => {
  return axios.get(`${serverUrl}/get?${query}`);
};

export const deleteFaq = (id) => {
  return axios.delete(`${serverUrl}/deleteById/${id}`);
};

export const updateFaq = (formData, id) => {
  return axios.patch(`${serverUrl}/updateById/${id}`, formData);
};
