import React, { useEffect, useState } from "react";
import CustomButton from "../Utility/Button";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getMessagesByTicket, sendMessageInChatById } from "../../redux/actions/Support/Support.actions";
import { generateFilePath } from "../Utility/utils";
import FileUpload from "../Utility/FileUpload";

function Chatbox() {
  const dispatch = useDispatch();
  const messages = useSelector((state) => state.support.messages);
  const support = useSelector((state) => state.support.supportObj);
  const authObj = useSelector((state) => state.auth);
  const [message, setMessage] = useState("");
  const [imageStr, setImageStr] = useState(null);
  let [searchParams, setSearchParams] = useSearchParams();

  const getMessages = () => {
    if (searchParams.get("ticketId")) {
      dispatch(getMessagesByTicket(searchParams.get("ticketId")));
    }
  };

  const handleSendMessageToSupport = async () => {
    if (message != "") {
      let obj = {
        ticketId: support._id,
        message,
        messageFrom: authObj?.user?._id,
      };
      dispatch(sendMessageInChatById(obj));
      dispatch(getMessagesByTicket(searchParams.get("ticketId")));
      setMessage("");
    }
    if (imageStr) {
      let obj = {
        ticketId: support._id,
        isImage: true,
        imageStr,
        messageFrom: authObj?.user?._id,
      };
      dispatch(sendMessageInChatById(obj));
      dispatch(getMessagesByTicket(searchParams.get("ticketId")));
      setImageStr(null);
    }
  };

  useEffect(() => {
    getMessages();
  }, []);

  return (
    <main class="position-relative">
      <section className="product-category" style={{ minHeight: "85vh" }}>
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-12">
              <h5 className="blue-1 mb-4">Chatbox</h5>
              <h6 className="blue-1">Title : {support?.title}</h6>
              <h6 className="blue-1 mb-4">Description : {support?.description}</h6>
              <ul>
                {messages &&
                  messages.length > 0 &&
                  messages.map((el, index) => {
                    return (
                      <li className="chat-box">
                        {el?.isImage && el?.imageFile ? (
                          <>
                            <a href={generateFilePath(el?.imageFile)} target="_blank">
                              <img src={generateFilePath(el?.imageFile)} style={{ height: 200 }} />
                            </a>
                          </>
                        ) : (
                          <>
                            <h6 className="blue-1">{el?.messageFromObj?.name}</h6>
                            <p>{el?.message}</p>
                          </>
                        )}
                      </li>
                    );
                  })}
              </ul>
              <form className="form row chat-description">
                <div className="col-10" style={{ position: "fixed", bottom: 10, right: 2 }}>
                  <div className="d-flex justify-content-between align-items-end mb-3">
                    <label>Your Message</label>
                    <div className="d-flex justify-content-between align-items-end">
                      <FileUpload onFileChange={(e) => setImageStr(e)} />
                      <CustomButton btntype="button" ClickEvent={() => handleSendMessageToSupport()} btnName="send" isBtn iconName="ion-paper-airplane" extraClass=" " />
                    </div>
                  </div>
                  <textarea value={message} onChange={(e) => setMessage(e.target.value)} name="message" rows="5" placeholder="Type Here" className="form-control"></textarea>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default Chatbox;
