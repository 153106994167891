import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { addCompanySettings, getCompanySettings, updateCompanySettings } from "../../services/CompanySettings.service";
import CustomButton from "../Utility/Button";
import { DashboardBox } from "../Utility/DashboardBox";
import { toastError, toastSuccess } from "../Utility/ToastUtils";

function CompanyInfo() {
  const [companyObj, setCompanyObj] = useState({});
  const navigate = useNavigate()
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [gstNumber, setGstNumber] = useState("");
  const [addressLine1, setAddressLine1] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  const [pincode, setPincode] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [referalAmount, setReferalAmount] = useState("10");
  const [orderGst, setOrderGst] = useState("18");
  const [shippingCharges, setShippingCharges] = useState(50);
  const [isUpdate, setIsUpdate] = useState(false);
  const getCompany = async () => {
    try {
      let { data: res } = await getCompanySettings();
      if (res) {
        setCompanyObj(res.data);
        if (res?.data?._id) {
          setIsUpdate(true);
          setName(res?.data?.name);
          setEmail(res?.data?.email);
          setPhone(res.data.phone);
          setGstNumber(res.data.gstNumber);
          setAddressLine1(res.data.addressObj?.line1);
          setAddressLine2(res.data.addressObj?.line2);
          setPincode(res.data.addressObj?.pincode);
          setState(res.data.addressObj?.state);
          setCity(res.data.addressObj?.city);
          setOrderGst(res.data.orderGst);
          setReferalAmount(res.data.referalAmount);
          setShippingCharges(res?.data?.shippingCharges)
          //  setA;
        }
      }
    } catch (error) {
      toastError(error);
    }
  };

  const handleSubmit = async () => {
    try {
      let obj = {
        name,
        email,
        phone,
        gstNumber,
        orderGst,
        referalAmount,
        addressObj: {
          line1: addressLine1,
          line2: addressLine2,
          city,
          state,
          country,
          pincode,
        },
        shippingCharges
      };
      if (isUpdate) {
        let { data: res } = await updateCompanySettings(companyObj?._id, obj);
        if (res) {
          toastSuccess(res.message);
          navigate(-1)
        }
      } else {
        let { data: res } = await addCompanySettings(obj);
        if (res) {
          toastSuccess(res.message);
          navigate(-1)
        }
      }
    } catch (error) {
      toastError(error);
    }
  };

  useEffect(() => {
    getCompany();
  }, []);

  return (
    <main>
      <section className="product-category">
        <div className="container-fluid p-0">
          <DashboardBox>
            <h5 className="blue-1 mb-4">Company Information</h5>
            <form className="form row">
              <div className="col-12 col-md-6">
                <label>Company Name</label>
                <input type="text" value={name} onChange={(e) => setName(e.target.value)} className="form-control mt-1" />
              </div>
              <div className="col-12 col-md-6">
                <label>Email</label>
                <input type="text" onChange={(e) => setEmail(e.target.value)} value={email} className="form-control mt-1" />
              </div>
              <div className="col-12 col-md-6">
                <label>Phone Number</label>
                <input type="number" value={phone} onChange={(e) => setPhone(e.target.value)} className="form-control mt-1" />
              </div>
              <div className="col-12 col-md-6">
                <label>GST Number</label>
                <input type="text" value={gstNumber} onChange={(e) => setGstNumber(e.target.value)} className="form-control mt-1" />
              </div>
              <div className="col-12 col-md-6">
                <label>Referal Amount</label>
                <input type="number" value={referalAmount} onChange={(e) => setReferalAmount(e.target.value)} className="form-control mt-1" />
              </div>
              <div className="col-12 col-md-6">
                <label>GST Percentage</label>
                <input type="number" value={orderGst} onChange={(e) => setOrderGst(e.target.value)} className="form-control mt-1" />
              </div>
              <div className="col-12 col-md-6">
                <label>Shipping Charges</label>
                <input type="number" value={shippingCharges} onChange={(e) => setShippingCharges(e.target.value)} className="form-control mt-1" />
              </div>
              <div className="col-12 col-md-6">
                <label>Address Line 1</label>
                <input type="text" value={addressLine1} onChange={(e) => setAddressLine1(e.target.value)} className="form-control mt-1" />
              </div>
              <div className="col-12 col-md-6">
                <label>Address Line2</label>
                <input type="text" value={addressLine2} onChange={(e) => setAddressLine2(e.target.value)} className="form-control mt-1" />
              </div>
              <div className="col-12 col-md-6">
                <label>City</label>
                <input type="text" value={city} onChange={(e) => setCity(e.target.value)} className="form-control mt-1" />
              </div>
              <div className="col-12 col-md-6">
                <label>State</label>
                <input type="text" value={state} onChange={(e) => setState(e.target.value)} className="form-control mt-1" />
              </div>
              <div className="col-12 col-md-6">
                <label>Pincode</label>
                <input type="number" value={pincode} onChange={(e) => setPincode(e.target.value)} className="form-control mt-1" />
              </div>

              <div className="col-12 text-center mt-4">
                <CustomButton ClickEvent={() => handleSubmit()} isBtn btntype="button" iconName="fa-solid fa-check" btnName="Save" />
              </div>
            </form>
          </DashboardBox>
        </div>
      </section>
    </main>
  );
}

export default CompanyInfo;
