import axios from "axios";
import { url } from "./url.service";

const serverUrl = url + "/companySettings";

export const addCompanySettings = (formData) => {
  return axios.post(serverUrl + "/add", formData);
};

export const getCompanySettings = (query) => {
  return axios.get(`${serverUrl}/get?${query}`);
};

export const deleteCompanySettings = (id) => {
  return axios.delete(`${serverUrl}/deleteById/${id}`);
};

export const updateCompanySettings = (id, formData) => {
  return axios.patch(`${serverUrl}/updateById/${id}`, formData);
};
