import { ArcElement, Chart as ChartJS, Legend, Tooltip } from "chart.js";
import React, { useEffect, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import DataTable from "react-data-table-component";
import { getCategory } from "../../services/category.service";
import { getProducts, getProductsForAdmin } from "../../services/product.service";
import CustomButton from "../Utility/Button";
import { DashboardBox, DashboardChart, DashboardTable } from "../Utility/DashboardBox";
import { toastError } from "../Utility/ToastUtils";
import { getDashboardData } from "../../services/index.routes";
import { useNavigate } from "react-router-dom";

function Dashboard() {
  const [dasboardDataObj, setDasboardDataObj] = useState(null);
  const navigate = useNavigate()
  const [productArr, setProductArr] = useState([]);
  const [topProducts, setTopProducts] = useState([]);
  const [publishedProducts, setPublishedProducts] = useState(0);
  const [totalProducts, setTotalProducts] = useState(0);

  const [categories, setcategories] = useState();
  const getTopProducts = async () => {
    try {
      const { data: res } = await getProductsForAdmin();
      if (res) {
        console.log("TOP", res)
        setTopProducts(res.data.filter(el => el.isTrending))
        setProductArr(res.data)
        // let tempArr = res.data;
        // setPublishedProducts(tempArr.filter(el => el.active).length)
        // setTotalProducts(tempArr.length)

      }
    } catch (error) {
      toastError(error)
    }
  }

  const getCategories = async () => {
    try {
      const { data: res } = await getCategory();
      if (res) {
        setcategories(res.data)
        console.log(res.data)
      }
    } catch (error) {
      toastError(error)
    }
  }

  useEffect(() => {
    getTopProducts()
    getCategories()
  }, [])


  const product_columns = [
    {
      name: "SL",
      selector: (row, index) => index + 1,
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => row.name,
    },
    {
      name: "Category ",
      selector: (row) => row?.categoryObj?.name,
    },
    {
      name: "Price ",
      selector: (row) => `₹ ${row?.categoryObj?.price}`,
    },
  ];

  const quality_columns = [
    {
      name: "SL",
      selector: (row, index) => index + 1,
      sortable: true,
    },
    {
      name: "Category Name",
      selector: (row) => row.name,
    },
    {
      name: "Price",
      selector: (row) => `₹ ${row?.price}`,
    },
  ];



  ChartJS.register(ArcElement, Tooltip, Legend);
  const productChartData = {
    labels: ["Published", "Total"],
    datasets: [
      {
        label: "Products",
        data: [publishedProducts, totalProducts],
        backgroundColor: ["rgba(54, 162, 235, 0.2)", "rgba(75, 192, 192, 0.2)"],
        borderColor: ["rgba(54, 162, 235)", "rgba(75, 192, 192)"],
        borderWidth: 1,
      },
    ],
  };
  const OrderChartData = {
    labels: ["Total", "Complete", "Processing", "pending"],
    datasets: [
      {
        label: "Orders Summary",
        data: [3, 0, 0, 0],
        backgroundColor: [
          "rgba(75, 192, 192, 0.2)", //green
          "rgba(54, 162, 235, 0.2)", //blue
          "rgba(255, 206, 86, 0.2)", //yellow
          "rgba(153, 102, 255, 0.2)", //purple
        ],
        borderColor: ["rgba(75, 192, 192)", "rgba(54, 162, 235)", "rgba(255, 206, 86)", "rgba(153, 102, 255)"],
        borderWidth: 1,
      },
    ],
  };



  const getData = async () => {
    try {
      const { data: res } = await getDashboardData();
      if (res) {
        setDasboardDataObj(res.data)
        if (res?.data?.totalProduct) {
          setTotalProducts(res?.data?.totalProduct)

        }
        if (res?.data?.activeProducts) {
          setPublishedProducts(res?.data?.activeProducts)
        }
      }
    } catch (error) {
      toastError(error)
    }
  }

  useEffect(() => {
    getData()
  }, [])



  return (
    <main>
      <section className="dashboard-head mb-5">
        <div className="container-fluid d-flex align-items-center justify-content-between">
          <h5 className="blue-1 mb-0">Summary</h5>

        </div>
      </section>

      <section className="mb-5">
        <div className="container-fluid">
          <div className="row">

            <div className="col-12 col-lg-3" onClick={() => navigate("/Order/Total-Order")}>
              <DashboardBox className="dashboard-summary" >
                <h5 className="blue-1">Total Orders</h5>
                <h4 className="text-dark mb-0">{dasboardDataObj?.totalOrders}</h4>
              </DashboardBox>
            </div>

            <div className="col-12 col-lg-3" onClick={() => navigate("/Order/Total-Order")}>
              <DashboardBox className="dashboard-summary">
                <h5 className="blue-1">Total Amount</h5>
                <h4 className="text-dark mb-0">₹ {dasboardDataObj?.totalAmount}</h4>
              </DashboardBox>
            </div>

            <div className="col-12 col-lg-3" onClick={() => navigate("/Product/Product-List")} >
              <DashboardBox className="dashboard-summary">
                <h5 className="blue-1">Total Product</h5>
                <h4 className="text-dark mb-0">{dasboardDataObj?.totalProduct}</h4>
              </DashboardBox>
            </div>
            {/* Customer-list */}
            <div className="col-12 col-lg-3" onClick={() => navigate("/Customer-list")}>
              <DashboardBox className="dashboard-summary">
                <h5 className="blue-1">Total Customers Approved</h5>
                <h4 className="text-dark mb-0">{dasboardDataObj?.totalApprovedCustomers}</h4>
              </DashboardBox>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-md-6" onClick={() => navigate("/Product/Product-List")}>
              <DashboardChart>
                <h5 className="blue-1 mb-4">Products</h5>
                <Doughnut data={productChartData} />
              </DashboardChart>
            </div>
            <div className="col-12 col-md-6" onClick={() => navigate("/Order/Total-Order")}>
              <DashboardChart>
                <h5 className="blue-1 mb-4">Orders Summary</h5>
                <Doughnut data={OrderChartData} />
              </DashboardChart>
            </div>

            {/* <div className="col-12 col-md-4 mb-5">
              <DashboardChart>
                <h5 className="blue-1 mb-4">Today Order summary</h5>
                <Doughnut data={TodayOrderChart} />
              </DashboardChart>
            </div> */}
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid mt-5" >
          <div className="row">
            <div className="col-12 col-md-6 mb-5" onClick={() => navigate("/Product/Product-List")}>
              <DashboardTable>
                <h5 className="blue-1 mb-4">Top 10 Product</h5>
                <DataTable columns={product_columns} data={topProducts} />
              </DashboardTable>
            </div>
            <div className="col-12 col-md-6 mb-5" onClick={() => navigate("/Product/Category")}>
              <DashboardTable>
                <h5 className="blue-1 mb-4">Top 10 Categoies</h5>
                <DataTable columns={quality_columns} data={categories} />
                <div className="text-center mt-4 mb-2">
                  {/* <CustomButton isLink noIcon btnName="SEE ALL" path="/" small roundedPill /> */}
                </div>
              </DashboardTable>
            </div>

          </div>
        </div>
      </section>
    </main>
  );
}

export default Dashboard;
