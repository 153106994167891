export const generalModelStatuses = {
  APPROVED: "APPROVED",
  DECLINED: "DECLINED",
  PENDING: "PENDING",
};

export const OrderStatus = {
  ORDERED: "ORDERED",
  PACKED: "PACKED",
  DISPATCHED: "DISPATCHED",
  DELIVERED: "DELIVERED",
  CANCELLED: "CANCELLED",
};
export const SupportTickets = {
  Open: "Open",
  Close: "Close",
};
