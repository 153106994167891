import axios from "axios";
import { url } from "./url.service";

const serverUrl = url + "/Terms";

export const addTerms = (formData) => {
  return axios.post(serverUrl + "/add", formData);
};

export const getTerms = (query) => {
  return axios.get(`${serverUrl}/get?${query}`);
};

export const deleteTerms = (id) => {
  return axios.delete(`${serverUrl}/deleteById/${id}`);
};

export const updateTerms = (formData, id) => {
  return axios.patch(`${serverUrl}/updateById/${id}`, formData);
};
