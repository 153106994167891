import * as GiftCardService from "../../../services/GiftCard.service";
export const ADD_GIFT_CARD = "ADD_GIFT_CARD";
export const ADD_GIFT_CARD_SUCCESS = "ADD_GIFT_CARD_SUCCESS";
export const ADD_GIFT_CARD_FAIL = "ADD_GIFT_CARD_FAIL";

export const GET_ALL_GIFT_CARD = "GET_ALL_GIFT_CARD";
export const GET_ALL_GIFT_CARD_SUCCESS = "GET_ALL_GIFT_CARD_SUCCESS";
export const GET_ALL_GIFT_CARD_FAIL = "GET_ALL_GIFT_CARD_FAIL";

export const UPDATE_GIFT_CARD_BY_ID = "UPDATE_GIFT_CARD_BY_ID";
export const UPDATE_GIFT_CARD_BY_ID_SUCCESS = "UPDATE_GIFT_CARD_BY_ID_SUCCESS";
export const UPDATE_GIFT_CARD_BY_ID_FAIL = "UPDATE_GIFT_CARD_BY_ID_FAIL";

export const DELETE_GIFT_CARD_BY_ID = "DELETE_GIFT_CARD_BY_ID";
export const DELETE_GIFT_CARD_BY_ID_SUCCESS = "DELETE_GIFT_CARD_BY_ID_SUCCESS";
export const DELETE_GIFT_CARD_BY_ID_FAIL = "DELETE_GIFT_CARD_BY_ID_FAIL";

export const SET_GIFT_CARD_OBJ = "SET_GIFT_CARD_OBJ";
export const SET_GIFT_CARD_OBJ_SUCCESS = "SET_GIFT_CARD_OBJ_SUCCESS";
export const SET_GIFT_CARD_OBJ_FAIL = "SET_GIFT_CARD_OBJ_FAIL";

export const giftCardAdd = (formData) => async (dispatch) => {
  try {
    dispatch({ type: ADD_GIFT_CARD });
    let { data: response } = await GiftCardService.addGiftCard(formData);
    if (response) {
      console.log(response);
      dispatch({
        type: ADD_GIFT_CARD_SUCCESS,
        payload: response.message,
      });
    }
  } catch (err) {
    console.error(err);
    dispatch({ type: ADD_GIFT_CARD_FAIL, payload: err });
  }
};

export const giftCardGet = (formData) => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_GIFT_CARD });
    let { data: response } = await GiftCardService.getAllGiftCard(formData);
    if (response) {
      console.log(response.data, "DATA");
      dispatch({
        type: GET_ALL_GIFT_CARD_SUCCESS,
        payload: { data: response.data, message: response.message },
      });
    }
  } catch (err) {
    console.error(err);
    dispatch({ type: GET_ALL_GIFT_CARD_FAIL, payload: err });
  }
};

export const setGiftCardObj = (formData) => async (dispatch) => {
  try {
    dispatch({ type: SET_GIFT_CARD_OBJ });
    console.log(formData, "ACTIon");
    if (formData && formData?.name) {
      dispatch({
        type: SET_GIFT_CARD_OBJ_SUCCESS,
        payload: formData,
      });
    } else {
      dispatch({
        type: SET_GIFT_CARD_OBJ_SUCCESS,
        payload: { data: null },
      });
    }
  } catch (err) {
    console.error(err);
    dispatch({ type: SET_GIFT_CARD_OBJ_FAIL, payload: err });
  }
};

export const giftCardUpdateById = (id, formData) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_GIFT_CARD_BY_ID });
    let { data: response } = await GiftCardService.updateGiftCardById(id, formData);
    if (response) {
      dispatch({
        type: UPDATE_GIFT_CARD_BY_ID_SUCCESS,
        payload: response.message,
      });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: UPDATE_GIFT_CARD_BY_ID_FAIL, payload: error });
  }
};

export const giftCardDeleteById = (id) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_GIFT_CARD_BY_ID });
    let { data: response } = await GiftCardService.deleteGiftCardById(id);
    if (response) {
      dispatch({
        type: DELETE_GIFT_CARD_BY_ID_SUCCESS,
        payload: response.message,
      });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: DELETE_GIFT_CARD_BY_ID_FAIL, payload: error });
  }
};
