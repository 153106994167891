import { debounce } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useDispatch } from "react-redux";
import "react-responsive-modal/styles.css";
import { useSearchParams } from "react-router-dom";
import { categoryWiseProductStock } from "../../services/product.service";
import { DashboardTable } from "../Utility/DashboardBox";
import { toastError } from "../Utility/ToastUtils";
function CategoryWiseProductStock() {
    const dispatch = useDispatch();

    // const productArr = useSelector((state) => state.product.products);
    const [searchParams, setSearchParams] = useSearchParams('')
    const [totalElements, setTotalElements] = useState(0);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [productsArr, setProductsArr] = useState([]);
    const [categoryName, setCategoryName] = useState('');
    const [searchStr, setSearchStr] = useState('');


    useEffect(() => {
        getProductsData(page,
            limit, "")
    }, []);








    const getProductsData = async (skipValue,
        limitValue, searchValue) => {
        try {
            let query = `skip=${skipValue}&limit=${limitValue}&searchValue=${searchValue}`;


            console.log(query, "query")
            const { data: res } = await categoryWiseProductStock(query, searchParams.get('id'));
            if (res) {
                console.log(res.data)
                setTotalElements(res.totalElements)
                setProductsArr([...res.data.map((el) => ({ ...el, checked: false }))]);
            }
        } catch (error) {
            toastError(error)
        }

    }

    const product_sale_columns = [
        {
            name: "SL",
            cell: (row, index) => index + 1,
            // sortable: true,
            // width: "5%",
        },
        {
            name: "Name",
            cell: (row) => row?.name,
            // width: "15%",
            // searchable: true,
        },

        {
            name: "Stock",
            cell: (row) => row?.stock,
            // width: "15%",
            // searchable: true,
        },


        // {
        //     name: "Action",
        //     cell: (row) => <>
        //         <ActionIcon
        //             Uniquekey={row.id}
        //             view
        //             viewpath="/"
        //             isRedirected={true}
        //         />
        //     </>,
        // },


    ];




    const debouncedSave = useCallback(
        // skipValue, limitValue, filterCondition, searchQueryValue, school, company
        debounce(
            (nextValue) =>
                getProductsData(
                    1,
                    limit,
                    nextValue,
                )(),
            1000
        ),

        [page, limit] // will be created only once initially
    );

    const handleChange = (value) => {
        setSearchStr(value);
        setPage(1)
        debouncedSave(value);
        // setLimit(10)
    };


    const handlePerRowsChange = (newPerPage, page) => {

        getProductsData(
            page,
            newPerPage,
            searchStr
        );
        setLimit(newPerPage);
    };
    const handlePageChange = (page) => {
        console.log(page, "handlePageChange");
        if (totalElements / page > 1) {
            setPage(page);
            getProductsData(
                page,
                limit,
                searchStr
            );
        }
    };


    return (
        <main>
            <section className="product-category">
                <div className="container-fluid p-0">
                    <div className="row">
                        <div className="col-12">
                            <div className="d-flex align-items-center justify-content-between mb-4">
                                <h5 className="blue-1">Category Wise Product Stock List</h5>
                            </div>
                            <div className="row">



                            </div>
                            <DashboardTable>
                                <DataTable columns={product_sale_columns} data={productsArr}
                                    pagination
                                    paginationServer
                                    paginationTotalRows={totalElements}
                                    onChangeRowsPerPage={handlePerRowsChange}
                                    onChangePage={handlePageChange} />
                            </DashboardTable>

                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
}

export default CategoryWiseProductStock;
